.embeddable-quote {
    margin: var(--spacing-xl) 0;

    &__text {
        display: flex;
        font-size: var(--header2);
        line-height: 1;

        &::before {
            content: '“';
        }
    }
    
    &__footer {
        align-items: center;
        display: flex;
        font-size: var(--header6);
        font-weight: var(--bold);
        margin-top: var(--spacing-xs);

        &::before {
            background-color: var(--secondary-light);
            content: '';
            height: .2rem;
            margin-right: var(--spacing-xs);
            width: 1rem;
        }
    }

    @media (min-width: $break-tablet) {
        margin: 4.8rem 0;

        &__text {
            font-size: var(--header1);
        }

        &__footer::before {
            width: 2rem;
        }
    }
}
