// stylelint-disable

// flatpickr overrides

.qs-datepicker-container {
    font-size: 1.5rem;
    width: 100%;
    max-width: 40rem;
    border: .1rem solid var(--neutral200);
}

.qs-datepicker {

    .qs-controls {
        background: var(--white);
        border-bottom: .1rem solid var(--neutral200);
    }

    .qs-square {
        height: 2.4em;
        border-radius: 0;
    }

    .qs-square.qs-active {
        background: var(--secondary);
        color: var(--white);
    }

    .qs-square:not( .qs-empty ):not( .qs-disabled ):not( .qs-day ):not( .qs-active ):hover {
        background: var(--neutral300);
    }

    .qs-arrow {
        height: 2.4em;
        width: 2.4em;
        border-radius: 0;
    }
}

// stylelint-enable
