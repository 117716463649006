.booking {
    $this: &;

    // HIDDEN INPUT

    &__hidden-input {
        display: none;

        &.is-active {
            display: block;
        }
    }

    // TABS

    &__tabs {
        align-items: center;
        display: flex;
        position: relative;
        flex-direction: row;
        justify-content: center;
        text-align: center;

        &::before {
            background-color: var(--secondary);
            content: '';
            height: .1rem;
            position: absolute;
            width: 100%;
        }
    }

    &__tab {
        background-color: var(--neutral50);
        border-radius: 3.2rem;
        color: var(--black);
        font-size: var(--default);
        font-weight: var(--bold);
        height: 4rem;
        line-height: 1.5;
        margin: 0 .4rem;
        padding: 0 4.8rem;
        text-align: center;
        transition: background-color .25s ease-in-out, color .25s ease-in-out;
        z-index: $level-one;
        
        &.is-active {
            background-color: var(--secondary);
            color: var(--white);
        }
    }

    // PANELS

    &__panel {
        display: none;
        padding: var(--spacing-m) 0;

        &.is-active {
            display: block;
        }

        &--flights,
        &--packages {

            #{$this}__row {
                max-width: 92rem;
                margin: 0 auto 2.4rem;
            }
        }
    }

    &__row {
        display: flex;
        justify-content: center;
        margin: 0 -2.4rem 4.8rem;
    }

    &__col {
        margin: 0 1rem;
        max-width: 31.3rem;
        width: calc(1/3 * 100%);
    }

    &__col-halfwidth {
        margin: 0 1rem;
        width: 50%;
    }

    &__title {
        color: var(--neutral900);
        font-size: var(--large);
        margin: var(--spacing-l) 0 4rem;
        text-align: center;
    }

    &__input-container {
        position: relative;

        #{$this}__form-input {
            padding: $spacing-s $spacing-s $spacing-s 4.8rem;
        }

        // ERROR STATE

        &.has-error {

            #{$this}__form-input {
                border: .1rem solid var(--accentRedDark);
            }

            #{$this}__required-text {
                display: block;
            }
        }

        &.destination-error {

            #{$this}__form-input {
                border: .1rem solid var(--accentRedDark);
            }

            #{$this}__required-destination {
                display: block;
            }
        }
    }

    &__required-text,
    &__required-destination {
        color: var(--accentRedDark);
        display: none;
        font-size: var(--link);
        margin-top: .4rem;
        position: absolute;
    }

    &__icon {
        position: absolute;
        top: 1.2rem;
        left: 1.4rem;
        width: 2.4rem;
        height: 2.4rem;
        pointer-events: none;
        fill: var(--neutral900);
    }

    &__col-fullwidth {
        padding-right: 2.4rem;
        padding-left: 2.4rem;
    }

    // BUTTON

    &__button-container {
        position: relative;
        clear: both;

        &::before {
            content: '';
            position: absolute;
            top: 50%;
            right: 0;
            left: 0;
            width: 100%;
            height: .1rem;
            margin: 0 auto;
            background-color: var(--secondary);
            z-index: -1;
        }
    }

    &__button {
        display: block;
        margin: 0 auto;
        text-align: center;
    }

    // PARTNER

    &__partner {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;

        &::before {
            background-color: var(--neutral100);
            content: '';
            height: .1rem;
            margin: .8rem 0 1.6rem;
            order: 1;
            width: 13.7rem;
        }

        .tampa-bay-logo {
            height: 4.8rem;
            order: 0;
            width: 16.7rem;
        }

        .expedia-logo {
            height: 1.6rem;
            order: 2;
            width: 13.6rem;
        }
    }

    &__checkbox {
        position: relative;
        margin-top: 1rem;
    }

    @media (max-width: $break-wide) {
        &__col {
            width: 50%;
        }
    }

    @media (max-width: $break-tablet) {
        &__row {
            display: block;
        }

        &__col-halfwidth,
        &__col {
            max-width: none;
            margin: 0 0 2rem;
            padding: 0 2.4rem;
            width: auto;
        }

        &__tab {
            flex: 1 1 0px;
            font-size: var(--meta);
            height: 4.4rem;
            padding: 0 2.4rem;
        }

        &__form {
            padding: 0;
        }

        &__button-container {
            margin-top: 3.2rem;
        }
    }

    @media (max-width: $break-phablet) {
        &__title {
            margin-top: 0;
        }

        &__col,
        &__col-halfwidth {
            float: none;
            padding: 0;
        }

        &__row {
            margin: 0;
        }

        &__tabs {
            margin: 0 auto;
            overflow-x: auto;
            scroll-snap-type: x mandatory;
            flex-wrap: nowrap;
            justify-content: flex-start;

            &::before {
                display: none;
            }
        }

        &__panel {
            padding: var(--spacing-xl) 0 var(--spacing-l);

            &--flights,
            &--packages {

                #{$this}__row {
                    max-width: none;
                    margin: 0 auto 2rem;
                }
            }
        }

        &__button-container {
            display: flex;

            &::before {
                display: none;
            }
        }

        &__button {
            flex-grow: 1;
        }
    }
}

