.wrapper {
    $this: &;
    margin: 0 auto;
    max-width: var(--widget-width);
    position: relative;
    padding: var(--spacing-xl) var(--spacing-m);

    @media (min-width: $break-tablet) {
        padding: var(--spacing-xxl);
    }
}