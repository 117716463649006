@charset "UTF-8";
/* stylelint-disable-next-line comment-empty-line-before */
/* SASS MODULES
========================================================================== */
/* SETTINGS
========================================================================== */
/* Breakpoints */
/* Z-Index Layers */
/* Transitions */
/* Spacing */
/*
 * Adds hover and focus styles for tablet (by default) and up
 * Only keeps focus styles for below tablet (by default)
 * If focus set to false then only hover styling will be applied
 */
/*
  This mixin can be used to set the object-fit:
  @include object-fit(contain);
  or object-fit and object-position:
  @include object-fit(cover, top);
  https://github.com/bfred-it/object-fit-images
*/
/**
 * This mixin can be used to define a line clamp limit
 * @type {Number}
 */
/**
 * This mixin can be used to add skeleton loading background
 */
/*
 * Applies an ellipsis to text that overflows the width passed into the mixin
 * NB this ONLY works on single lines of text
 */
.theme-mood-hibiscus {
  --mood-primary: #ae2375;
  --mood-secondary: var(--white, #fff);
}
.theme-mood-tan {
  --mood-primary: #cfac7c;
  --mood-secondary: var(--neutral900, #141d38);
}
.theme-mood-elm {
  --mood-primary: #1b806d;
  --mood-secondary: var(--white, #fff);
}
.theme-mood-bondi-blue {
  --mood-primary: #00a4af;
  --mood-secondary: var(--white, #fff);
}
.theme-mood-flush-orange {
  --mood-primary: #ff8200;
  --mood-secondary: var(--neutral900, #141d38);
}

/* VENDORS
========================================================================== */
.autocomplete-suggestions {
  text-align: left;
  cursor: default;
  border: 0.1rem solid var(--neutral300);
  border-top: 0;
  background: var(--white);
  box-shadow: -0.1rem 0.1rem 0.3rem rgba(0, 0, 0, 0.1);
  margin-top: -0.1rem;
  /* core styles should not be changed */
  position: absolute;
  display: none;
  z-index: 9999;
  max-height: 25.4rem;
  overflow: hidden;
  overflow-y: auto;
  box-sizing: border-box;
}

.autocomplete-suggestion {
  position: relative;
  padding: 0.3rem 1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 1.4rem;
  color: var(--neutral900);
}

.autocomplete-suggestion b {
  font-weight: normal;
  color: var(--neutral900);
}

.autocomplete-suggestion.selected {
  background: var(--neutral50);
}

.qs-datepicker-container {
  font-size: 1rem;
  font-family: sans-serif;
  color: #000;
  position: absolute;
  width: 15.625em;
  display: flex;
  flex-direction: column;
  z-index: 9001;
  user-select: none;
  border: 1px solid grey;
  border-radius: 0.26392em;
  overflow: hidden;
  background: #fff;
  box-shadow: 0 1.25em 1.25em -0.9375em rgba(0, 0, 0, 0.3);
}

.qs-datepicker-container * {
  box-sizing: border-box;
}

.qs-datepicker-container.qs-centered {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.qs-datepicker-container.qs-hidden {
  display: none;
}

.qs-datepicker .qs-overlay {
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.75);
  color: #fff;
  width: 100%;
  height: 100%;
  padding: 0.5em;
  z-index: 1;
  opacity: 1;
  transition: opacity 0.3s;
  display: flex;
  flex-direction: column;
}

.qs-datepicker .qs-overlay.qs-hidden {
  opacity: 0;
  z-index: -1;
}

.qs-datepicker .qs-overlay .qs-overlay-year {
  background: rgba(0, 0, 0, 0);
  border: none;
  border-bottom: 1px solid #fff;
  border-radius: 0;
  color: #fff;
  font-size: 0.875em;
  padding: 0.25em 0;
  width: 80%;
  text-align: center;
  margin: 0 auto;
  display: block;
}

.qs-datepicker .qs-overlay .qs-overlay-year::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.qs-datepicker .qs-overlay .qs-close {
  padding: 0.5em;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
}

.qs-datepicker .qs-overlay .qs-submit {
  border: 1px solid #fff;
  border-radius: 0.26392em;
  padding: 0.5em;
  margin: 0 auto auto;
  cursor: pointer;
  background: hsla(0, 0%, 50.2%, 0.4);
}

.qs-datepicker .qs-overlay .qs-submit.qs-disabled {
  color: grey;
  border-color: grey;
  cursor: not-allowed;
}

.qs-datepicker .qs-overlay .qs-overlay-month-container {
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
}

.qs-datepicker .qs-overlay .qs-overlay-month {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 33.33333%;
  cursor: pointer;
  opacity: 0.5;
  transition: opacity 0.15s;
}

.qs-datepicker .qs-overlay .qs-overlay-month.active,
.qs-datepicker .qs-overlay .qs-overlay-month:hover {
  opacity: 1;
}

.qs-datepicker .qs-controls {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  flex-shrink: 0;
  background: #d3d3d3;
  filter: blur(0);
  transition: filter 0.3s;
}

.qs-datepicker .qs-controls.qs-blur {
  filter: blur(5px);
}

.qs-datepicker .qs-arrow {
  height: 1.5625em;
  width: 1.5625em;
  position: relative;
  cursor: pointer;
  border-radius: 0.26392em;
  transition: background 0.15s;
}

.qs-datepicker .qs-arrow:hover {
  background: rgba(0, 0, 0, 0.1);
}

.qs-datepicker .qs-arrow:hover.qs-left::after {
  border-right-color: #000;
}

.qs-datepicker .qs-arrow:hover.qs-right::after {
  border-left-color: #000;
}

.qs-datepicker .qs-arrow::after {
  content: "";
  border: 0.39062em solid rgba(0, 0, 0, 0);
  position: absolute;
  top: 50%;
  transition: border 0.2s;
}

.qs-datepicker .qs-arrow.qs-left::after {
  border-right-color: grey;
  right: 50%;
  transform: translate(25%, -50%);
}

.qs-datepicker .qs-arrow.qs-right::after {
  border-left-color: grey;
  left: 50%;
  transform: translate(-25%, -50%);
}

.qs-datepicker .qs-month-year {
  font-weight: 700;
  transition: border 0.2s;
  border-bottom: 1px solid rgba(0, 0, 0, 0);
  cursor: pointer;
}

.qs-datepicker .qs-month-year:hover {
  border-bottom: 1px solid grey;
}

.qs-datepicker .qs-month-year:active:focus,
.qs-datepicker .qs-month-year:focus {
  outline: none;
}

.qs-datepicker .qs-month {
  padding-right: 0.5ex;
}

.qs-datepicker .qs-year {
  padding-left: 0.5ex;
}

.qs-datepicker .qs-squares {
  display: flex;
  flex-wrap: wrap;
  padding: 0.3125em;
  filter: blur(0);
  transition: filter 0.3s;
}

.qs-datepicker .qs-squares.qs-blur {
  filter: blur(5px);
}

.qs-datepicker .qs-square {
  width: 14.28571%;
  height: 1.5625em;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background 0.1s;
  border-radius: 0.26392em;
}

.qs-datepicker .qs-square.qs-current {
  font-weight: 700;
}

.qs-datepicker .qs-square.qs-current span {
  text-decoration: underline;
}

.qs-datepicker .qs-square.qs-active {
  background: #add8e6;
}

.qs-datepicker .qs-square.qs-range-date-start:not(.qs-range-date-6) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.qs-datepicker .qs-square.qs-range-date-middle {
  background: #d4ebf2;
}

.qs-datepicker .qs-square.qs-range-date-middle:not(.qs-range-date-0):not(.qs-range-date-6) {
  border-radius: 0;
}

.qs-datepicker .qs-square.qs-range-date-middle.qs-range-date-0 {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.qs-datepicker .qs-square.qs-range-date-end:not(.qs-range-date-0),
.qs-datepicker .qs-square.qs-range-date-middle.qs-range-date-6 {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.qs-datepicker .qs-square.qs-disabled span {
  opacity: 0.2;
}

.qs-datepicker .qs-square.qs-empty {
  cursor: default;
}

.qs-datepicker .qs-square.qs-disabled {
  cursor: not-allowed;
  color: var(--neutral300);
}

.qs-datepicker .qs-square.qs-day {
  cursor: default;
  font-weight: 700;
  color: grey;
}

.qs-datepicker .qs-square:not(.qs-empty):not(.qs-disabled):not(.qs-day):not(.qs-active):hover {
  background: orange;
}

.qs-datepicker .qs-event {
  position: relative;
}

.qs-datepicker .qs-event::after {
  content: "";
  position: absolute;
  width: 0.46875em;
  height: 0.46875em;
  border-radius: 50%;
  background: #07f;
  bottom: 0;
  right: 0;
}

.qs-datepicker .qs-event.qs-disabled::after,
.qs-datepicker .qs-event.qs-empty::after {
  background: #cce4ff;
}

/* LAYOUT
========================================================================== */
/* GLOBAL
========================================================================== */
/*
---- If Font is served through local files the following mixins can be used ----
*/
@font-face {
  font-family: CentraNo2;
  src: url("../fonts/CentraNo2-Medium.woff") format("woff"), url("../fonts/CentraNo2-Medium.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: CentraNo2;
  src: url("../fonts/CentraNo2-Extrabold.woff") format("woff"), url("../fonts/CentraNo2-Extrabold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: CentraNo2;
  src: url("../fonts/CentraNo2-Book.woff") format("woff"), url("../fonts/CentraNo2-Book.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: CentraNo2;
  src: url("../fonts/CentraNo2-Bold.woff") format("woff"), url("../fonts/CentraNo2-Bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: CentraNo2;
  src: url("../fonts/CentraNo2-Light.woff") format("woff"), url("../fonts/CentraNo2-Light.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
html {
  max-width: 100%;
  font-size: 62.5%;
  height: 100%;
  overflow-x: hidden;
}
@media (min-width: 840px) {
  html {
    height: auto;
  }
}

body {
  --white: #fff;
  --black: #000;
  --logoText: #fff;
  --neutral50: #F8F5F4;
  --neutral100: #f0ebe8;
  --neutral200: #d7d4d2;
  --neutral300: #b9b6b8;
  --neutral400: #94949e;
  --neutral500: #707070;
  --neutral600: #575B6E;
  --neutral700: #343B53;
  --neutral800: #1E2842;
  --neutral900: #141D38;
  --neutral1000: #161a27;
  --neutral900light: #141D38;
  --accentRed: #EB5433;
  --accentRedDark: #ca2e2b;
  --accentGreen: #0D7066;
  --accentGreenDark: #075758;
  --accentBlue: #4E78E6;
  --accentBlueDark: #3662d8;
  --expediaBlue: #202843;
  --rgbaNeutral800: 32, 40, 67;
  --rgbaNeutral900: 22, 26, 39;
  --primary: var(--white);
  --primaryDark: var(--neutral900);
  --primaryDarker: #274491;
  --secondary-light: #0182C5;
  --secondary: #3662D8;
  --secondaryDark: #ffb64c;
  --secondaryDarker: #daa52c;
  --tertiary: #FFDF00;
  --text: var(--black);
  --lightText: #F8F5F4;
  --button: var(--secondary);
  --buttonDisabled: var(--neutral400);
  --buttonHovered: var(--white);
  --socialButton: var(--lightText);
  --imageBackground: #161a27;
  --sliderBar: #ffc94c;
  --vjsSelectedText: #202843;
  --yellow: #ffc94c;
  --yellowDark: #ffba19;
  --rgbaSecondary: 255, 201, 76;
  --light: 300;
  --regular: 400;
  --medium: 500;
  --bold: 700;
  --extra-bold: 800;
  --aspect16-10: 62.5%;
  --aspect16-9: 56.25%;
  --aspect1-1: 100%;
  --widget-width: 142.4rem;
  --widget-width-narrow: 69.6rem;
  --widget-padding-mobile: 1.6rem;
  --widget-padding-desktop: 2.4rem;
  --nav-height-desktop-sticky: 5.2rem;
  --nav-height-desktop: 8.8rem;
  --lozenge-height: 4.8rem;
  --nav-height-mobile: 11.2rem;
  --spacing-xxs: .4rem;
  --spacing-xs: .8rem;
  --spacing-s: 1.2rem;
  --spacing-m: 1.6rem;
  --spacing-l: 2.4rem;
  --spacing-xl: 3.2rem;
  --spacing-xxl: 6.4rem;
  --spacing-xxxl: 12.8rem;
  --cardShadow: 0 10px 14px 0 rgb(0 0 0 / 3%);
  --popupGradient: linear-gradient(180deg, rgb(22 26 39 / 0%) 15.57%, rgb(22 26 39 / 88%) 63.85%, var(--imageBackground) 100%);
  --controlsGradientDark: linear-gradient(270deg, rgb(32 40 67 / 70%) 0%, rgb(32 40 67 / 70%) 100%);
  --controlsGradientLight: linear-gradient(270deg, rgb(248 245 244 / 70%) 0%, rgb(248 245 244 / 70%) 100%);
  --bannerGradient: linear-gradient(257.32deg, rgb(22 26 39 / 0%) 9.19%, rgb(22 26 39 / 90%) 100.02%);
  --heroChannelGradientLight: linear-gradient(180deg, rgb(248 245 244 / 0.01%) 39.92%, rgb(248 245 244 / 80%) 72.52%, #F8F5F4 93.92%);
  --heroChannelGradientDark: linear-gradient(to bottom, rgb(32 40 67 / 0%) 40%, var(--neutral800) 100%);
  --default: 1.6rem;
  --large: 2rem;
  --header1: 6.4rem;
  --header2: 4rem;
  --header3: 3.9rem;
  --header4: 3.2rem;
  --header5: 2.5rem;
  --header6: var(--default);
  --meta: 1.4rem;
  --key: 1.3rem;
  --link: 1.2rem;
  --small: 1.1rem;
  --tiny: 1rem;
  --spacing: 1.6rem;
  background: var(--primary);
  color: var(--text);
  max-width: 100%;
  margin: 0;
  padding: 0;
  font-family: CentraNo2, helvetica, sans-serif;
  font-size: var(--default);
  overflow-x: hidden;
}
@media (min-width: 840px) {
  body {
    height: auto;
  }
}

.body-content {
  padding-top: var(--nav-height-mobile);
}
@media (min-width: 840px) {
  .body-content {
    padding-top: calc(var(--nav-height-desktop) + var(--lozenge-height));
  }
}

button {
  color: inherit;
  cursor: pointer;
  border: none;
  background: inherit;
  font-family: inherit;
}

h1, h2, h3, h4, h5, h6, p {
  color: var(--black);
  margin: 0 0 var(--spacing-m);
}

ul {
  margin: 0;
}

*, *::before, *::after {
  box-sizing: border-box;
}

svg {
  fill: var(--text);
}

/* Remove Autocomplete styles */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: none;
  -webkit-text-fill-color: inherit;
  box-shadow: 0 0 0 1000px white inset;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: var(--bold);
  line-height: 1.2;
}

h1 {
  font-size: var(--header1);
}

h2 {
  font-size: var(--header2);
}

h3 {
  font-size: var(--header3);
}

h4 {
  font-size: var(--header4);
}

h5 {
  font-size: var(--header5);
}

h6 {
  font-size: var(--header6);
}

p {
  color: var(--text);
  font-size: var(--default);
}

a {
  color: var(--secondary-light);
  font-weight: var(--medium);
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}

.fade-in-on-load {
  opacity: 0;
  transition: opacity 0.25s cubic-bezier(0, 0.4, 0.5, 1);
}
.fade-in-on-load.is-loaded {
  opacity: 1;
}

@keyframes skeleton-loading {
  to {
    background-position-x: -20%;
  }
}
@keyframes slide-in {
  0% {
    transform: translate(100%);
  }
  100% {
    transform: translate(0);
  }
}
@keyframes slide-up {
  0% {
    opacity: 0;
    transform: translateY(1rem);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes video-intro {
  0% {
    opacity: 0;
    transform: scale(0.9);
  }
  50% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(1);
  }
}
@keyframes dot-mobile {
  0% {
    transform: scale(0.4);
  }
  50% {
    box-shadow: 0 0 9px 9px var(--tertiary);
    transform: scale(1);
  }
  100% {
    transform: scale(0.4);
  }
}
@keyframes dot-desktop {
  0% {
    transform: scale(0.7);
  }
  50% {
    box-shadow: 0 0 9px 9px var(--tertiary);
    transform: scale(1);
  }
  100% {
    transform: scale(0.7);
  }
}
/* COMPONENTS
========================================================================== */
/* stylelint-disable selector-max-specificity */
.video-js .vjs-overlay {
  display: none;
}

.bc-player-default_default .vjs-big-play-button {
  margin: 0;
  width: 5.6rem;
  height: 5.6rem;
  background-color: var(--button);
  transform: translate(-50%, -50%);
}

.bc-player-default_default:hover .vjs-big-play-button {
  background-color: var(--neutral50);
}

.video-js .vjs-big-play-button .vjs-icon-placeholder::before {
  font-size: 3.6rem;
  line-height: 6;
  color: var(--primaryDark);
}

.video-jjs .vjs-slider,
.video-js .vjs-load-progress {
  background-color: #202843;
}

.video-js .vjs-load-progress div {
  background-color: rgba(87, 91, 110, 0.4);
}

.video-js .vjs-play-progress.vjs-slider-bar {
  background-color: var(--sliderBar);
}

.bc-player-default_default .vjs-control-bar {
  background-color: #141d38;
}

.bc-player-default_default .vjs-playlist-endscreen-control {
  display: none;
}

.bc-player-default_default .vjs-time-control.vjs-time-divider {
  padding: 0;
  width: auto;
  min-width: auto;
  font-size: 1.3rem;
  line-height: 3;
}

.bc-player-default_default .vjs-time-control .vjs-current-time-display,
.bc-player-default_default .vjs-time-control .vjs-duration-display {
  font-size: 1.3rem;
}

.video-js .vjs-play-control,
.video-js .vjs-mute-control,
.video-js .vjs-playback-rate,
.video-js .vjs-fullscreen-control,
.video-js .vjs-playlist-endscreen-control {
  border-right: 1px solid rgba(255, 255, 255, 0.1);
}

.video-js .vjs-playback-rate {
  padding: 0 3rem;
}

.video-js.bc-player-default_default .vjs-menu-item.vjs-menu-item {
  background-color: #202843;
}

.video-js.bc-player-default_default .vjs-menu-item.vjs-menu-item.vjs-selected {
  background-color: var(--button);
  color: var(--vjsSelectedText);
}
.video-js.bc-player-default_default .vjs-menu-item.vjs-menu-item.vjs-selected:focus {
  background-color: var(--vjsSelectedText);
  color: var(--button);
}
@media (min-width: 840px) {
  .video-js.bc-player-default_default .vjs-menu-item.vjs-menu-item.vjs-selected:hover {
    background-color: var(--vjsSelectedText);
    color: var(--button);
  }
}

.video-js.bc-player-default_default .vjs-menu-item.vjs-menu-item:hover {
  background-color: #141d38;
}

.video-js .vjs-volume-level {
  background-color: var(--button);
}

.bc-player-default_default .vjs-menu-button-popup .vjs-menu .vjs-menu-item:active,
.bc-player-default_default .vjs-menu-button-popup .vjs-menu .vjs-menu-item:focus,
.bc-player-default_default .vjs-menu-button-popup .vjs-menu .vjs-menu-item:hover {
  text-shadow: none;
}

.video-js .vjs-playlist-endscreen {
  display: none;
}

.button {
  align-items: center;
  background-color: var(--button);
  border-radius: 3.2rem;
  /* so we can animate on hover */
  border: 0.1rem solid var(--secondary);
  color: var(--white);
  cursor: pointer;
  display: flex;
  font-size: var(--default);
  font-weight: var(--bold);
  justify-content: center;
  line-height: 1.5;
  min-width: 14.8rem;
  padding: 1.1rem 3.2rem;
  transition: background-color 0.3s, border-color 0.3s, color 0.3s;
}
.button svg {
  fill: currentcolor;
  flex-shrink: 0;
}
.button:focus {
  border-color: var(--button);
  background-color: var(--white);
  color: var(--button);
  text-decoration: none;
}
.button:focus svg {
  fill: currentcolor;
}
@media (min-width: 840px) {
  .button:hover {
    border-color: var(--button);
    background-color: var(--white);
    color: var(--button);
    text-decoration: none;
  }
  .button:hover svg {
    fill: currentcolor;
  }
}
.button--transparent {
  background-color: transparent;
  border-color: var(--button);
  border-radius: 5rem;
  color: var(--button);
}
.button--transparent:focus {
  border-color: var(--button);
  background-color: transparent;
  color: var(--button);
  text-decoration: none;
}
.button--transparent:focus svg {
  fill: currentcolor;
}
@media (min-width: 840px) {
  .button--transparent:hover {
    border-color: var(--button);
    background-color: transparent;
    color: var(--button);
    text-decoration: none;
  }
  .button--transparent:hover svg {
    fill: currentcolor;
  }
}

.qs-datepicker-container {
  font-size: 1.5rem;
  width: 100%;
  max-width: 40rem;
  border: 0.1rem solid var(--neutral200);
}

.qs-datepicker .qs-controls {
  background: var(--white);
  border-bottom: 0.1rem solid var(--neutral200);
}
.qs-datepicker .qs-square {
  height: 2.4em;
  border-radius: 0;
}
.qs-datepicker .qs-square.qs-active {
  background: var(--secondary);
  color: var(--white);
}
.qs-datepicker .qs-square:not(.qs-empty):not(.qs-disabled):not(.qs-day):not(.qs-active):hover {
  background: var(--neutral300);
}
.qs-datepicker .qs-arrow {
  height: 2.4em;
  width: 2.4em;
  border-radius: 0;
}

fieldset {
  margin: 0;
  padding: 0;
  border: none;
}

.fieldset {
  padding: 0 4rem 4rem 4rem;
  border-radius: 0;
  margin: 0 0 4rem 0;
  border: 0.1rem solid var(--neutral200);
  position: relative;
}

legend {
  font-size: 1.8rem;
  margin: 0 0 1.2rem 0;
  line-height: 1;
  display: block;
  font-weight: normal;
}

.legend {
  font-size: 2.8rem;
  margin: 0 0 3rem 0;
  line-height: 1;
  display: block;
  font-family: var(--font-family);
  font-weight: normal;
}

label,
.label {
  font-size: var(--meta);
  font-weight: var(--bold);
  color: var(--neutral900);
  margin-bottom: 1.2rem;
  display: block;
  text-transform: none;
}

.label-helper {
  font-size: 1.5rem;
  margin: -1rem 0 1.2rem 0;
  color: var(--neutral800);
  line-height: 1.4;
}

input[type=text],
input[type=password],
input[type=date],
input[type=datetime],
input[type=datetime-local],
input[type=month],
input[type=week],
input[type=email],
input[type=number],
input[type=search],
input[type=tel],
input[type=time],
input[type=url],
input[type=color],
input[type=search],
textarea,
select {
  padding: 1.2rem;
  appearance: none;
  border-radius: 0.4rem;
  background-color: var(--white);
  border: solid 0.1rem var(--neutral200);
  display: block;
  font-family: inherit;
  font-size: 1.6rem;
  width: 100%;
  height: 4.8rem;
  color: var(--neutral500);
  box-sizing: border-box;
  max-width: 80rem;
}
input[type=text]::-webkit-input-placeholder,
input[type=password]::-webkit-input-placeholder,
input[type=date]::-webkit-input-placeholder,
input[type=datetime]::-webkit-input-placeholder,
input[type=datetime-local]::-webkit-input-placeholder,
input[type=month]::-webkit-input-placeholder,
input[type=week]::-webkit-input-placeholder,
input[type=email]::-webkit-input-placeholder,
input[type=number]::-webkit-input-placeholder,
input[type=search]::-webkit-input-placeholder,
input[type=tel]::-webkit-input-placeholder,
input[type=time]::-webkit-input-placeholder,
input[type=url]::-webkit-input-placeholder,
input[type=color]::-webkit-input-placeholder,
input[type=search]::-webkit-input-placeholder,
textarea::-webkit-input-placeholder,
select::-webkit-input-placeholder {
  color: var(--neutral500);
  font-size: inherit;
}
input[type=text]:-moz-placeholder,
input[type=password]:-moz-placeholder,
input[type=date]:-moz-placeholder,
input[type=datetime]:-moz-placeholder,
input[type=datetime-local]:-moz-placeholder,
input[type=month]:-moz-placeholder,
input[type=week]:-moz-placeholder,
input[type=email]:-moz-placeholder,
input[type=number]:-moz-placeholder,
input[type=search]:-moz-placeholder,
input[type=tel]:-moz-placeholder,
input[type=time]:-moz-placeholder,
input[type=url]:-moz-placeholder,
input[type=color]:-moz-placeholder,
input[type=search]:-moz-placeholder,
textarea:-moz-placeholder,
select:-moz-placeholder {
  color: var(--neutral500);
  font-size: inherit;
}
input[type=text]::-moz-placeholder,
input[type=password]::-moz-placeholder,
input[type=date]::-moz-placeholder,
input[type=datetime]::-moz-placeholder,
input[type=datetime-local]::-moz-placeholder,
input[type=month]::-moz-placeholder,
input[type=week]::-moz-placeholder,
input[type=email]::-moz-placeholder,
input[type=number]::-moz-placeholder,
input[type=search]::-moz-placeholder,
input[type=tel]::-moz-placeholder,
input[type=time]::-moz-placeholder,
input[type=url]::-moz-placeholder,
input[type=color]::-moz-placeholder,
input[type=search]::-moz-placeholder,
textarea::-moz-placeholder,
select::-moz-placeholder {
  color: var(--neutral500);
  font-size: inherit;
}
input[type=text]::-ms-input-placeholder,
input[type=password]::-ms-input-placeholder,
input[type=date]::-ms-input-placeholder,
input[type=datetime]::-ms-input-placeholder,
input[type=datetime-local]::-ms-input-placeholder,
input[type=month]::-ms-input-placeholder,
input[type=week]::-ms-input-placeholder,
input[type=email]::-ms-input-placeholder,
input[type=number]::-ms-input-placeholder,
input[type=search]::-ms-input-placeholder,
input[type=tel]::-ms-input-placeholder,
input[type=time]::-ms-input-placeholder,
input[type=url]::-ms-input-placeholder,
input[type=color]::-ms-input-placeholder,
input[type=search]::-ms-input-placeholder,
textarea::-ms-input-placeholder,
select::-ms-input-placeholder {
  color: var(--neutral500);
  font-size: inherit;
}
input[type=text]:focus,
input[type=password]:focus,
input[type=date]:focus,
input[type=datetime]:focus,
input[type=datetime-local]:focus,
input[type=month]:focus,
input[type=week]:focus,
input[type=email]:focus,
input[type=number]:focus,
input[type=search]:focus,
input[type=tel]:focus,
input[type=time]:focus,
input[type=url]:focus,
input[type=color]:focus,
input[type=search]:focus,
textarea:focus,
select:focus {
  border: 0.1rem solid var(--neutral800);
  outline: none;
}
input[type=text]:disabled,
input[type=password]:disabled,
input[type=date]:disabled,
input[type=datetime]:disabled,
input[type=datetime-local]:disabled,
input[type=month]:disabled,
input[type=week]:disabled,
input[type=email]:disabled,
input[type=number]:disabled,
input[type=search]:disabled,
input[type=tel]:disabled,
input[type=time]:disabled,
input[type=url]:disabled,
input[type=color]:disabled,
input[type=search]:disabled,
textarea:disabled,
select:disabled {
  background-color: var(--neutral100);
  cursor: not-allowed;
  color: var(--neutral400);
}

textarea {
  padding: 1.7rem 3rem;
  min-height: 10rem;
  border-radius: 1.5rem;
}

select {
  padding: 0 5rem 0 2rem;
  line-height: 2.6;
}
select::-ms-expand {
  display: none;
}

select[multiple] {
  padding: 1.5rem 3rem;
  border-radius: 2rem;
  background: var(--neutral200);
  height: inherit;
}

input[type=checkbox],
input[type=radio] {
  font-size: 1.6rem;
}
input[type=checkbox] + label,
input[type=radio] + label {
  margin: 0 2rem 0 0;
  font-size: 1.6rem;
  font-weight: normal;
  color: var(--neutral500);
  display: flex;
  align-items: center;
}

input[type=file] {
  font-size: 1.6rem;
}

input[type=radio].custom-radio {
  padding: 0;
  border: 0;
  clip: rect(0 0 0 0);
  height: 0.1rem;
  margin: -0.1rem;
  overflow: hidden;
  position: absolute;
  width: 0.1rem;
  top: 2.3rem;
  margin-left: 1.3rem;
}

input[type=radio].custom-radio ~ label::before {
  width: 2.3rem;
  height: 2.3rem;
  margin-right: 1rem;
  content: "";
  display: inline-block;
  background: var(--neutral200);
  border: 0.4rem solid var(--white);
  border-radius: 50%;
  -webkit-border-radius: 50%;
  box-shadow: 0 0 0 0.1rem var(--neutral200);
  vertical-align: middle;
  position: relative;
  top: -0.1rem;
}

input[type=radio].custom-radio:focus ~ label::before {
  box-shadow: 0 0 0 0.1rem var(--secondary);
}

input[type=radio].custom-radio:checked ~ label::before {
  background: var(--secondary);
  vertical-align: middle;
}

input[type=checkbox].custom-checkbox,
input[type=radio].custom-checkbox {
  padding: 0;
  border: 0;
  clip: rect(0 0 0 0);
  height: 0.1rem;
  margin: -0.1rem;
  overflow: hidden;
  position: absolute;
  width: 0.1rem;
  top: 2.3rem;
  margin-left: 1.3rem;
}

input[type=checkbox].custom-checkbox ~ label::before,
input[type=radio].custom-checkbox ~ label::before {
  content: "";
  display: inline-block;
  background: transparent;
  border: 0.1rem solid var(--neutral200);
  border-radius: 0.2rem;
  vertical-align: middle;
  position: relative;
  width: 2.4rem;
  height: 2.4rem;
  margin-right: 1.2rem;
}

input[type=checkbox].custom-checkbox:focus ~ label::before,
input[type=radio].custom-checkbox:focus ~ label::before {
  border: 0.1rem solid var(--secondary);
}

input[type=checkbox].custom-checkbox:disabled ~ label::before,
input[type=radio].custom-checkbox:disabled ~ label::before,
input[type=checkbox].custom-checkbox:disabled ~ label,
input[type=radio].custom-checkbox:disabled ~ label {
  opacity: 0.5;
}

input[type=checkbox].custom-checkbox:checked ~ label::before,
input[type=radio].custom-checkbox:checked ~ label::before {
  content: "";
  vertical-align: middle;
  background-image: url("../i/elements/tick.svg");
  background-size: 2rem;
  background-position: center;
  background-repeat: no-repeat;
}

input[type=radio].custom-radio ~ label,
input[type=checkbox].custom-checkbox ~ label,
input[type=radio].custom-checkbox ~ label {
  margin-bottom: 2rem;
}

.required {
  color: var(--secondary);
}

.input-container {
  margin-right: 7rem;
  position: relative;
}
.input-container.is-error input,
.input-container.is-error textarea {
  border: 0.2rem solid o(var(--secondary), 50);
}
.input-container.is-valid input,
.input-container.is-valid textarea {
  border: 0.2rem solid o(var(--secondary), 50);
}

.input-group {
  display: table;
  width: 100%;
}
.input-group input {
  white-space: nowrap;
  display: table-cell;
  vertical-align: middle;
}
.input-group .input-group-button {
  padding-top: 0;
  padding-bottom: 0;
  margin: 0;
  display: table-cell;
  text-align: center;
  vertical-align: top;
  width: 1%;
}
.input-group .input-group-button button {
  margin-left: 2rem;
  white-space: nowrap;
}

.linked-list {
  all: unset;
}
@media (min-width: 840px) {
  .linked-list {
    display: flex;
  }
}

.meta-data {
  display: flex;
  flex-wrap: wrap;
  font-weight: var(--light);
  transition: opacity 0.5s ease-in-out 0.5s;
}
.meta-data__item {
  align-items: center;
  color: var(--neutral300);
  display: flex;
  font-size: var(--meta);
  font-weight: var(--light);
  margin-bottom: 0.5rem;
  margin-right: 1.2rem;
}
.meta-data__item svg {
  margin-right: 0.4rem;
  fill: currentcolor;
  height: 1.6rem;
  width: 1.6rem;
}
.meta-data__item::before {
  background-color: var(--neutral300);
  border-radius: 100%;
  content: "";
  display: inline-block;
  height: 0.4rem;
  margin-right: 1.2rem;
  width: 0.4rem;
}
.meta-data__item:first-child::before {
  content: none;
}
.meta-data__progress-bar-container {
  background-color: var(--neutral600);
  position: relative;
  height: 0.4rem;
  width: 7rem;
}
.meta-data__progress-bar {
  background-color: var(--button);
  inset: 0 auto 0 0;
  position: absolute;
}
.meta-data--inline-video {
  margin: 2.4rem 0 0;
}
.meta-data--season {
  position: relative;
  margin: 0 0 2.4rem;
}
.meta-data--season .meta-data__item {
  color: var(--white);
}
.meta-data--series-promo {
  margin-top: 2.4rem;
}
.meta-data--series-promo .meta-data__item {
  color: var(--neutral300);
}
.meta-data--channel-hero {
  margin: 2.4rem 0;
}
.meta-data--channel-hero .meta-data__item {
  color: var(--neutral300);
}
@media (min-width: 375px) {
  .meta-data__progress-bar-container {
    width: 10rem;
  }
}
@media (min-width: 1024px) {
  .meta-data__progress-bar-container {
    width: 14rem;
  }
  .meta-data--series-promo .meta-data__item {
    color: var(--white);
  }
}

.object-fit-cover-picture__img {
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
  font-family: "object-fit: cover; object-position: center";
}

.static-hero {
  background-color: var(--colorPlaceholder);
  align-items: flex-end;
  display: flex;
  height: 39rem;
  justify-content: center;
  max-width: 100%;
  position: relative;
  width: 100%;
}
.static-hero::after {
  background: linear-gradient(180deg, rgba(32, 40, 67, 0) 10%, var(--neutral800) 100%);
  content: "";
  inset: 0;
  position: absolute;
}
.static-hero--shareable {
  height: 47rem;
  margin-bottom: 3.2rem;
}
.static-hero__content {
  padding-bottom: 5rem;
  position: relative;
  text-align: center;
  z-index: 10;
}
.static-hero__logo {
  margin-bottom: 2rem;
  max-height: 12rem;
}
.static-hero__description {
  font-weight: var(--light);
  line-height: 1.2;
  margin: 0 auto;
  max-width: 35rem;
  padding: 0 1.2rem;
}
@media (min-width: 425px) {
  .static-hero__description {
    max-width: 50rem;
  }
  .static-hero__logo {
    max-height: 15rem;
  }
}
@media (min-width: 840px) {
  .static-hero {
    height: 40rem;
  }
  .static-hero--shareable {
    height: 47rem;
  }
  .static-hero__description {
    max-width: 67rem;
  }
}

.tag {
  align-items: center;
  background-color: var(--accentBlue);
  border-radius: 3rem;
  color: var(--white);
  display: flex;
  font-size: var(--link);
  font-weight: var(--bold);
  line-height: 1;
  margin-top: 1.2rem;
  padding: 0.3rem 0.4rem;
  z-index: 10;
}
.tag svg {
  fill: var(--white);
  height: 1.2rem;
  margin-right: 0.4rem;
  width: 1.2rem;
}
.tag--new-episode {
  background-color: var(--accentRedDark);
  border-radius: 4rem;
  left: 1.2rem;
  margin: 0;
  padding: 0.4rem 0.8rem;
  position: absolute;
  top: 1.2rem;
}

.video-play-cta {
  display: flex;
  align-items: center;
  margin: 0.8rem 0;
  word-break: break-word;
}
.video-play-cta__button {
  align-items: center;
  background-color: var(--button);
  border-radius: 100%;
  border: 0.1rem solid transparent;
  cursor: pointer;
  display: flex;
  height: 5.6rem;
  justify-content: center;
  transition: transform 0.5s ease-in-out, background-color 0.3s;
  z-index: 2;
  width: 5.6rem;
}
.video-play-cta__button:focus {
  background-color: rgb(var(--rgbaNeutral800) 0.7);
  border-color: var(--button);
}
.video-play-cta__button:focus svg {
  fill: var(--button);
}
@media (min-width: 840px) {
  .video-play-cta__button:hover {
    background-color: rgb(var(--rgbaNeutral800) 0.7);
    border-color: var(--button);
  }
  .video-play-cta__button:hover svg {
    fill: var(--button);
  }
}
.video-play-cta__button svg {
  fill: var(--primaryDark);
  height: 4rem;
  width: 4rem;
}
.video-play-cta__description {
  padding-left: 1.6rem;
}
.video-play-cta__progress {
  color: var(--secondary);
  font-size: var(--meta);
  font-weight: var(--light);
}
.video-play-cta__title {
  color: var(--secondary);
  font-weight: var(--bold);
  line-height: 1.2;
  margin: 0;
}
@media (min-width: 1024px) {
  .video-play-cta {
    margin: 2.4rem 0;
  }
}

.widget-header__subtitle {
  font-size: var(--default);
  font-weight: var(--bold);
  padding: 0 var(--widget-padding-mobile);
  margin-top: 0;
}
.widget-header__title {
  color: var(--secondary-light);
  line-height: 1.2;
  margin-top: 0.2rem;
}
.widget-header__promo-rail .widget-header__subtitle,
.widget-header__promo-rail .widget-header__title {
  padding: 0 var(--widget-padding-mobile);
}
@media (min-width: 840px) {
  .widget-header__promo-rail .widget-header__subtitle,
  .widget-header__promo-rail .widget-header__title {
    padding: 0 var(--widget-padding-desktop);
  }
}
@media (min-width: 1200px) {
  .widget-header__subtitle {
    padding: 0;
  }
  .widget-header__title {
    margin-bottom: 1.5rem;
    padding: 0;
  }
}

.widget {
  margin: 2.4rem auto 0;
  max-width: var(--widget-width);
  position: relative;
  padding: 0;
}
.widget__list {
  display: flex;
  margin: 0.8rem -1.8rem 0;
  padding: 0 1.2rem 1rem;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  overflow: scroll;
}
.widget--narrow {
  max-width: var(--widget-width-narrow);
  overflow: hidden;
}
@media (min-width: 840px) {
  .widget {
    margin-top: 4.8rem;
    padding: 0 var(--widget-padding-desktop);
  }
  .widget__list {
    scroll-snap-type: none;
    transition: transform 0.6s ease-in-out;
    margin: 0 -0.6rem;
    padding: 0;
    position: relative;
    overflow: initial;
  }
  .widget--narrow .widget__list-wrapper {
    overflow: hidden;
  }
  .widget--narrow .widget__list {
    margin: 0 -0.6rem;
  }
}

.wrapper {
  margin: 0 auto;
  max-width: var(--widget-width);
  position: relative;
  padding: var(--spacing-xl) var(--spacing-m);
}
@media (min-width: 840px) {
  .wrapper {
    padding: var(--spacing-xxl);
  }
}

/* WIDGETS
========================================================================== */
.error-404 {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100dvh;
  justify-content: center;
  margin-top: calc(-1 * var(--nav-height-desktop));
}
.error-404__title {
  color: var(--secondary);
  font-size: var(--header1);
  margin: 3.2rem 0;
}
.error-404__link {
  color: var(--text);
  display: flex;
  font-size: var(--header3);
  align-items: center;
}
.error-404__link svg {
  height: 6.4rem;
  width: 6.4rem;
}
@media (max-width: 1200px) {
  .error-404 {
    margin-top: calc(-1 * var(--nav-height-mobile));
  }
  .error-404__title {
    font-size: var(--header3);
  }
  .error-404__link {
    font-size: var(--header5);
  }
  .error-404__link svg {
    height: 3.2rem;
    width: 3.2rem;
  }
}

.article-related {
  margin: 0 auto;
  position: relative;
  padding: 0;
}
@media (min-width: 840px) {
  .article-related {
    max-width: 64.8rem;
    overflow: hidden;
  }
}
.article-related__list-item {
  display: block;
  flex: 0 0 auto;
  margin: 0 0.6rem;
  list-style: none;
  scroll-snap-align: center;
  position: relative;
  max-width: 20.8rem;
  width: 100%;
}
.article-related__img {
  aspect-ratio: 208/117;
  border-radius: 1.2rem;
  overflow: hidden;
  position: relative;
}
.article-related__img::after {
  background: linear-gradient(180deg, rgba(22, 26, 39, 0) 0%, rgba(22, 26, 39, 0.85) 100%);
  content: "";
  height: 3.6rem;
  inset: auto 0 0;
  position: absolute;
}
.article-related hr {
  all: unset;
  display: block;
  background-color: var(--secondary-light);
  height: 0.1rem;
  margin-bottom: 1.2rem;
  opacity: 0.5;
  width: 4rem;
}
.article-related__read-time {
  color: var(--neutral500);
  display: flex;
  font-size: var(--meta);
  margin-top: 1.2rem;
}
.article-related__read-time svg {
  fill: var(--neutral500);
  height: 1.6rem;
  margin-right: 0.4rem;
  width: 1.6rem;
}
.article-related__item-title {
  color: var(--neutral900);
  line-height: 1.5;
  height: 4.8rem;
  margin: 0.8rem 0 1.2rem;
}
@supports (-webkit-line-clamp: 2) {
  .article-related__item-title {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    /* autoprefixer: on */
    -webkit-line-clamp: 2;
    overflow: hidden;
  }
}
.article-related__item-description {
  color: var(--neutral900);
  font-size: var(--meta);
  font-weight: var(--regular);
  margin: 0;
}
@supports (-webkit-line-clamp: 3) {
  .article-related__item-description {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    /* autoprefixer: on */
    -webkit-line-clamp: 3;
    overflow: hidden;
  }
}

/* stylelint-disable custom-property-pattern */
.banner-simplified {
  background: var(--secondary-light);
  border-top: 0.1rem solid var(--neutral700);
  border-bottom: 0.1rem solid var(--neutral700);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3rem var(--widget-padding-mobile) 3.5rem;
  position: relative;
}
.banner-simplified p, .banner-simplified h2 {
  color: var(--lightText);
  font-size: var(--header5);
  font-weight: var(--bold);
  max-width: 84.1rem;
  text-align: center;
  margin: 0;
}
.banner-simplified strong {
  color: var(--tertiary);
}
.banner-simplified pre {
  color: var(--lightText);
  font-family: inherit;
  font-weight: var(--regular);
  font-size: 1.5rem;
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 0;
}
.banner-simplified .button {
  border: 0;
  background-color: var(--tertiary);
  color: var(--neutral900);
  margin-top: 1.4rem;
}
.banner-simplified .button:hover {
  background-color: var(--white);
}

.blog__header {
  position: relative;
  height: calc(100dvh - (var(--nav-height-desktop) + var(--lozenge-height)));
  max-height: 63.9rem;
}
.blog__image {
  all: unset;
  inset: 0;
  position: absolute;
}
.blog__image::before {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.37) 53.11%, rgba(0, 0, 0, 0.27) 62.77%, rgba(0, 0, 0, 0) 92.52%) no-repeat;
  content: "";
  inset: 0;
  pointer-events: none;
  position: absolute;
  z-index: 10;
}
.blog__image::after {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
  content: "";
  height: 50.3rem;
  inset: auto 0 0;
  pointer-events: none;
  position: absolute;
  z-index: 10;
}
.blog__intro {
  inset: auto 0 0;
  position: absolute;
  margin-bottom: 0;
  padding-bottom: 0;
  padding-top: 0;
  z-index: 20;
}
.blog__heading {
  color: var(--tertiary);
  font-size: var(--header2);
  margin-bottom: var(--spacing-xl);
  max-width: 53.5rem;
}
.blog__heading--small {
  font-size: var(--header2);
}
.blog__summary {
  color: var(--white);
  font-weight: var(--bold);
  margin-bottom: var(--spacing-xl);
  max-width: 53.5rem;
}
.blog__meta {
  align-items: flex-end;
  border-top: 0.1rem solid var(--white);
  color: var(--white);
  display: flex;
  font-size: 1.6rem;
  padding: var(--spacing-xl) 0 var(--spacing-l);
  justify-content: space-between;
  line-height: 1;
}
.blog__date {
  font-size: var(--meta);
}
.blog__date::after {
  content: "•";
  display: inline;
  font-size: 1.5rem;
  margin-right: 0.5rem;
  margin-left: 0.8rem;
}
.blog__read-time {
  font-size: var(--meta);
}
.blog__author::before, .blog__caption::before {
  content: "•";
  font-size: var(--meta);
  display: inline;
  margin-right: 0.8rem;
  margin-left: 0.5rem;
}
.blog__caption {
  font-size: var(--meta);
  font-weight: var(--regular);
  float: right;
}
.blog__caption::before {
  display: none;
}
.blog__content {
  position: relative;
  max-width: 64.6rem;
  margin: 0 auto;
}
.blog__content h1, .blog__content h2, .blog__content h3, .blog__content h4, .blog__content h5, .blog__content h6 {
  color: var(--secondary-light);
}
.blog__share {
  position: absolute;
  top: 0;
  right: -27rem;
  width: 8rem;
  min-height: 30rem;
}
.blog__cta {
  align-items: center;
  display: flex;
  position: relative;
  max-width: 64.6rem;
  margin: 0 auto;
  padding: 4.4rem 0;
  text-align: center;
}
.blog__cta::before {
  background-color: var(--neutral300);
  position: absolute;
  width: 100%;
  height: 0.1rem;
  content: "";
}
.blog__cta-button {
  background-color: var(--white);
  display: block;
  margin: 0 auto;
  min-width: 12.8rem;
  padding: 1rem 1.6rem;
  text-align: center;
}
.blog iframe {
  aspect-ratio: 16/9;
  width: 100%;
}
@media (max-width: 840px) {
  .blog__header {
    height: calc(100dvh - var(--nav-height-mobile));
    max-height: 60rem;
  }
  .blog__heading {
    font-size: var(--header4);
    margin-bottom: var(--spacing-m);
  }
  .blog__heading--small {
    font-size: var(--header4);
  }
  .blog__summary {
    font-size: var(--default);
    margin-bottom: var(--spacing-m);
  }
  .blog__meta {
    padding: var(--spacing) 0 var(--spacing-l);
  }
  .blog__cta::before {
    content: normal;
  }
  .blog__cta-button {
    width: 100%;
    text-align: center;
  }
}

.booking__hidden-input {
  display: none;
}
.booking__hidden-input.is-active {
  display: block;
}
.booking__tabs {
  align-items: center;
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: center;
  text-align: center;
}
.booking__tabs::before {
  background-color: var(--secondary);
  content: "";
  height: 0.1rem;
  position: absolute;
  width: 100%;
}
.booking__tab {
  background-color: var(--neutral50);
  border-radius: 3.2rem;
  color: var(--black);
  font-size: var(--default);
  font-weight: var(--bold);
  height: 4rem;
  line-height: 1.5;
  margin: 0 0.4rem;
  padding: 0 4.8rem;
  text-align: center;
  transition: background-color 0.25s ease-in-out, color 0.25s ease-in-out;
  z-index: 10;
}
.booking__tab.is-active {
  background-color: var(--secondary);
  color: var(--white);
}
.booking__panel {
  display: none;
  padding: var(--spacing-m) 0;
}
.booking__panel.is-active {
  display: block;
}
.booking__panel--flights .booking__row, .booking__panel--packages .booking__row {
  max-width: 92rem;
  margin: 0 auto 2.4rem;
}
.booking__row {
  display: flex;
  justify-content: center;
  margin: 0 -2.4rem 4.8rem;
}
.booking__col {
  margin: 0 1rem;
  max-width: 31.3rem;
  width: 33.3333333333%;
}
.booking__col-halfwidth {
  margin: 0 1rem;
  width: 50%;
}
.booking__title {
  color: var(--neutral900);
  font-size: var(--large);
  margin: var(--spacing-l) 0 4rem;
  text-align: center;
}
.booking__input-container {
  position: relative;
}
.booking__input-container .booking__form-input {
  padding: 1.2rem 1.2rem 1.2rem 4.8rem;
}
.booking__input-container.has-error .booking__form-input {
  border: 0.1rem solid var(--accentRedDark);
}
.booking__input-container.has-error .booking__required-text {
  display: block;
}
.booking__input-container.destination-error .booking__form-input {
  border: 0.1rem solid var(--accentRedDark);
}
.booking__input-container.destination-error .booking__required-destination {
  display: block;
}
.booking__required-text, .booking__required-destination {
  color: var(--accentRedDark);
  display: none;
  font-size: var(--link);
  margin-top: 0.4rem;
  position: absolute;
}
.booking__icon {
  position: absolute;
  top: 1.2rem;
  left: 1.4rem;
  width: 2.4rem;
  height: 2.4rem;
  pointer-events: none;
  fill: var(--neutral900);
}
.booking__col-fullwidth {
  padding-right: 2.4rem;
  padding-left: 2.4rem;
}
.booking__button-container {
  position: relative;
  clear: both;
}
.booking__button-container::before {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  width: 100%;
  height: 0.1rem;
  margin: 0 auto;
  background-color: var(--secondary);
  z-index: -1;
}
.booking__button {
  display: block;
  margin: 0 auto;
  text-align: center;
}
.booking__partner {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.booking__partner::before {
  background-color: var(--neutral100);
  content: "";
  height: 0.1rem;
  margin: 0.8rem 0 1.6rem;
  order: 1;
  width: 13.7rem;
}
.booking__partner .tampa-bay-logo {
  height: 4.8rem;
  order: 0;
  width: 16.7rem;
}
.booking__partner .expedia-logo {
  height: 1.6rem;
  order: 2;
  width: 13.6rem;
}
.booking__checkbox {
  position: relative;
  margin-top: 1rem;
}
@media (max-width: 1440px) {
  .booking__col {
    width: 50%;
  }
}
@media (max-width: 840px) {
  .booking__row {
    display: block;
  }
  .booking__col-halfwidth, .booking__col {
    max-width: none;
    margin: 0 0 2rem;
    padding: 0 2.4rem;
    width: auto;
  }
  .booking__tab {
    flex: 1 1 0px;
    font-size: var(--meta);
    height: 4.4rem;
    padding: 0 2.4rem;
  }
  .booking__form {
    padding: 0;
  }
  .booking__button-container {
    margin-top: 3.2rem;
  }
}
@media (max-width: 640px) {
  .booking__title {
    margin-top: 0;
  }
  .booking__col, .booking__col-halfwidth {
    float: none;
    padding: 0;
  }
  .booking__row {
    margin: 0;
  }
  .booking__tabs {
    margin: 0 auto;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .booking__tabs::before {
    display: none;
  }
  .booking__panel {
    padding: var(--spacing-xl) 0 var(--spacing-l);
  }
  .booking__panel--flights .booking__row, .booking__panel--packages .booking__row {
    max-width: none;
    margin: 0 auto 2rem;
  }
  .booking__button-container {
    display: flex;
  }
  .booking__button-container::before {
    display: none;
  }
  .booking__button {
    flex-grow: 1;
  }
}

.card-tile__link {
  display: block;
  position: relative;
}
.card-tile__link::after {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  border-radius: 0.8rem;
  content: "";
  inset: 0;
  position: absolute;
}
.card-tile__link:focus .card-tile__bg-image {
  transform: scale(1.1);
}
@media (min-width: 840px) {
  .card-tile__link:hover .card-tile__bg-image {
    transform: scale(1.1);
  }
}
.card-tile__inner {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
}
.card-tile__content {
  inset: auto 0 0;
  margin: 0;
  padding: 0 var(--spacing-m) var(--spacing-xl);
  position: absolute;
  text-align: center;
  z-index: 10;
}
.card-tile__image {
  border-radius: 0.8rem;
  overflow: hidden;
}
.card-tile__bg-image {
  position: relative;
  height: 50rem;
  transition: transform 0.4s ease;
}
.card-tile__title {
  color: var(--white);
  font-weight: var(--bold);
  margin-bottom: 3.6rem;
  position: relative;
}
.card-tile__title::after {
  background-color: var(--tertiary);
  bottom: -1.6rem;
  content: "";
  height: 0.4rem;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  width: 8.6rem;
}
.card-tile__description {
  color: var(--white);
  font-weight: var(--medium);
  line-height: 1.5;
  margin: 0;
}
@media (max-width: 840px) {
  .card-tile:first-child {
    margin-left: 1.6rem;
  }
  .card-tile:last-child {
    margin-right: 1.6rem;
  }
  .card-tile__bg-image {
    height: 36.9rem;
  }
  .card-tile__content {
    padding: 0 var(--spacing-s) var(--spacing-s);
  }
  .card-tile__title {
    font-size: var(--header5);
    margin-bottom: 2.8rem;
  }
  .card-tile__title::after {
    bottom: -1.6rem;
  }
}
@media (max-width: 640px) {
  .card-tile {
    scroll-snap-align: center;
  }
}

.cards-grid {
  overflow-x: hidden;
  position: relative;
}
.cards-grid__inner {
  position: relative;
}
.cards-grid__header {
  margin-bottom: var(--spacing-xl);
  text-align: center;
}
.cards-grid__title {
  margin: 0 auto var(--spacing-m);
  max-width: 87rem;
  text-align: center;
}
.cards-grid__description {
  max-width: 87rem;
  margin: 0 auto;
  line-height: 1.8;
  text-align: center;
}
.cards-grid__row {
  display: flex;
  flex-wrap: wrap;
  gap: 1.6rem;
}
.cards-grid__item {
  flex-shrink: 0;
  max-width: calc(33.3333333333% - 1.1rem);
  min-width: 0;
  width: 100%;
}
@media (max-width: 1200px) {
  .cards-grid__item {
    max-width: calc(50% - 0.8rem);
  }
}
@media (max-width: 840px) {
  .cards-grid__inner {
    margin-bottom: 0;
  }
  .cards-grid__header {
    margin-bottom: var(--spacing-l);
    padding: 0 var(--spacing-m);
  }
  .cards-grid__row {
    flex-wrap: nowrap;
    justify-content: flex-start;
    margin: 0 -1.6rem;
    margin-top: 0;
    padding: 0 0 2rem;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    gap: 1.6rem;
  }
  .cards-grid__row::-webkit-scrollbar {
    height: 0.3rem;
  }
  .cards-grid__row::-webkit-scrollbar-thumb {
    color: var(--secondary);
    background: var(--secondary);
  }
  .cards-grid__title {
    font-size: var(--header3);
  }
  .cards-grid__item {
    max-width: 28rem;
    width: 100%;
  }
}

.content {
  background-color: var(--white, #fff);
  max-width: 87rem;
  text-align: center;
}
@media (min-width: 840px) {
  .content {
    padding: var(--spacing-xxl) 0;
  }
}
.content::after {
  background-color: var(--tertiary);
  content: "";
  display: block;
  height: 0.4rem;
  margin-top: 6.4rem;
}
@media (max-width: 840px) {
  .content::after {
    margin-top: var(--spacing-xl);
  }
}
.content__title {
  font-size: var(--header2);
  margin: 0 0 var(--spacing-xl);
  text-align: center;
  color: var(--neutral900);
}
@media (max-width: 840px) {
  .content__title {
    font-size: var(--header5);
    margin: 0 0 var(--spacing-l);
  }
}
.content__content p {
  font-size: var(--default);
  line-height: 1.5;
}
@media (max-width: 840px) {
  .content__content p {
    margin: 0 0 var(--spacing-l);
  }
}
.content__content a {
  font-size: inherit;
  color: var(--secondary-light);
  font-weight: var(--bold);
}
.content__partnership {
  position: relative;
  font-weight: var(--bold);
  font-size: var(--link);
  margin: 0;
  text-align: center;
  text-transform: uppercase;
}

.cookie-notice {
  z-index: 20;
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 3rem 4.4rem;
  background-color: var(--white);
  box-shadow: 0 -0.1rem 0.2rem 0 rgba(0, 0, 0, 0.1);
  transition: transform 0.5s 0.25s;
}
.cookie-notice--hide {
  transform: translateY(100%);
}
.cookie-notice__content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.cookie-notice__content--left {
  padding-right: 3rem;
}
.cookie-notice__title {
  font-size: 3.8rem;
  color: var(--secondary);
  margin-bottom: 0.5rem;
}
.cookie-notice__text, .cookie-notice__link {
  color: var(--text);
  font-size: 1.7rem;
  line-height: 1.6;
}
.cookie-notice__link {
  font-weight: var(--bold);
}
.cookie-notice__accept-button {
  padding: 1.6rem 6rem;
}

@media (max-width: 840px) {
  .cookie-notice {
    padding: 2rem 3.4rem;
  }
  .cookie-notice__content {
    display: block;
  }
  .cookie-notice__content--left {
    display: block;
    width: 100%;
    padding: 0;
  }
  .cookie-notice__content--right {
    display: block;
  }
  .cookie-notice__title {
    font-size: 2.4rem;
  }
  .cookie-notice__text, .cookie-notice__link {
    font-size: 1.4rem;
  }
  .cookie-notice__accept-button {
    margin: 1rem 0;
    padding: 1.2rem 1.6rem;
  }
}
.embeddable-photo {
  margin: 0 0 4.8rem;
}
.embeddable-photo__title {
  font-size: 2rem;
  margin-bottom: 3rem;
}
.embeddable-photo__figure {
  all: unset;
  position: relative;
}
.embeddable-photo__figure .lazy-image-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.embeddable-photo__image-wrapper {
  background-color: var(--neutral500);
  border-radius: 0.8rem;
  border-right: 0.6rem solid var(--tertiary);
  border-left: 0.6rem solid var(--tertiary);
  position: relative;
}
.embeddable-photo__image-wrapper::before {
  content: "";
  display: block;
  padding-bottom: 56.25%;
}
.embeddable-photo__caption {
  color: var(--black);
  font-size: var(--meta);
  font-style: italic;
  line-height: 1;
  margin-top: var(--spacing-xs);
  text-align: left;
}
.embeddable-photo__summary {
  font-style: italic;
  margin-top: 3rem;
}
@media (max-width: 1200px) {
  .embeddable-photo__caption {
    position: static;
    width: auto;
    margin-top: 2rem;
  }
}
@media (max-width: 840px) {
  .embeddable-photo {
    margin: 0 0 var(--spacing-xl);
  }
  .embeddable-photo__caption {
    margin-top: 0.4rem;
  }
}

.embeddable-quote {
  margin: var(--spacing-xl) 0;
}
.embeddable-quote__text {
  display: flex;
  font-size: var(--header2);
  line-height: 1;
}
.embeddable-quote__text::before {
  content: "“";
}
.embeddable-quote__footer {
  align-items: center;
  display: flex;
  font-size: var(--header6);
  font-weight: var(--bold);
  margin-top: var(--spacing-xs);
}
.embeddable-quote__footer::before {
  background-color: var(--secondary-light);
  content: "";
  height: 0.2rem;
  margin-right: var(--spacing-xs);
  width: 1rem;
}
@media (min-width: 840px) {
  .embeddable-quote {
    margin: 4.8rem 0;
  }
  .embeddable-quote__text {
    font-size: var(--header1);
  }
  .embeddable-quote__footer::before {
    width: 2rem;
  }
}

.footer {
  background: var(--black);
  display: flex;
  flex-direction: column;
  padding: 2rem var(--spacing-l);
  z-index: 10;
}
.footer__copyright {
  color: var(--white);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 3.2rem 0;
}
.footer__copyright svg {
  height: 1.6rem;
  margin: 0 0.6rem;
  width: 11rem;
}
.footer__copyright span {
  align-items: center;
  display: flex;
  font-size: var(--tiny);
  font-weight: var(--light);
  line-height: 1.6rem;
  margin: 0;
  white-space: nowrap;
}
.footer__copyright span:first-child {
  margin-right: 0.4rem;
}
.footer .linked-list {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
}
.footer .linked-list__item {
  margin-right: 1rem;
  list-style: none;
  display: flex;
}
.footer .linked-list__item:not(:first-child) {
  align-items: center;
  gap: 1rem;
}
.footer .linked-list__item:not(:first-child)::before {
  content: "";
  display: block;
  width: 0.4rem;
  height: 0.4rem;
  border-radius: 50%;
  background-color: var(--white);
}
.footer .linked-list__link {
  color: var(--white);
  font-size: var(--tiny);
  font-weight: var(--light);
  line-height: 1.6rem;
  white-space: nowrap;
}
.footer__social {
  display: flex;
  justify-content: flex-start;
  order: -1;
}
.footer__social-link {
  margin: 0 0.8rem;
}
.footer__social-link svg {
  fill: var(--white);
  height: 1.6rem;
  transition: fill 0.25s ease;
  width: 1.6rem;
}
.footer__social-link svg:hover {
  fill: var(--secondary);
}
.footer__social-link:first-child {
  margin-left: 0;
}
.footer__social-link:last-child {
  margin-right: 0;
}
@media (min-width: 640px) {
  .footer {
    align-items: center;
  }
  .footer__copyright span:first-child {
    margin-bottom: 0;
  }
  .footer__social {
    align-items: center;
  }
}
@media (min-width: 1024px) {
  .footer {
    height: 6.4rem;
    flex-direction: row;
    border-top: none;
  }
  .footer__copyright {
    flex-direction: row;
    margin: 0;
  }
  .footer__copyright span:first-child {
    margin: 0;
  }
  .footer .linked-list {
    margin: 0 0 0 9.8rem;
  }
  .footer__social {
    order: 1;
    margin-left: auto;
  }
}
@media (min-width: 1200px) {
  .footer {
    padding-left: var(--spacing-xxl);
    padding-right: var(--spacing-xxl);
  }
}

.inline-video {
  position: relative;
  width: 100%;
}
.inline-video::before {
  background-color: var(--tertiary);
  content: "";
  inset: 50% 0 0;
  position: absolute;
}
.inline-video .wrapper {
  margin-bottom: 0;
  position: relative;
}
.inline-video--video-playing .inline-video__figure::before, .inline-video--video-playing .inline-video__figure::after {
  opacity: 0;
  pointer-events: none;
}
.inline-video--video-playing .inline-video__content, .inline-video--video-playing .inline-video__background, .inline-video--video-playing .inline-video__btn-container {
  opacity: 0;
  pointer-events: none;
}
.inline-video--video-playing .inline-video__video {
  /* stylelint-disable-next-line declaration-no-important */
  opacity: 1 !important;
}
.inline-video__content {
  align-items: center;
  display: flex;
  flex-direction: column;
  inset: var(--spacing-xxl);
  justify-content: flex-end;
  margin: auto;
  max-width: 64.4rem;
  position: absolute;
  text-align: center;
  transition: all ease-out 1s;
  z-index: 10;
}
.inline-video__title {
  color: var(--white);
}
.inline-video__description {
  color: var(--white);
  margin-bottom: var(--spacing-xxl);
}
.inline-video__figure {
  aspect-ratio: 16/9;
  border-radius: 1.2rem;
  margin: 0;
  overflow: hidden;
  position: relative;
}
.inline-video__figure::before, .inline-video__figure::after {
  content: "";
  position: absolute;
  transition: opacity ease-out 1s;
  z-index: 10;
}
.inline-video__figure::before {
  background-color: rgba(0, 0, 0, 0.3);
  inset: 0;
}
.inline-video__figure::after {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
  height: 46.3rem;
  inset: auto 0 0;
}
.inline-video__video {
  display: block;
  height: 100%;
  object-fit: cover;
  opacity: 0;
  transition: opacity ease-out 1s;
  width: 100%;
}
.inline-video__btn-container {
  align-items: center;
  display: flex;
  gap: 1.5rem;
  inset: 0;
  justify-content: center;
  margin: auto;
  position: absolute;
  z-index: 20;
}
.inline-video__btn-container svg {
  fill: var(--white);
  height: 4.2rem;
  margin: auto;
  pointer-events: none;
  width: 4.2rem;
}
.inline-video__icon-pause {
  display: none;
}
.inline-video__play-pause-btn {
  background-color: var(--secondary);
  border-radius: 1rem;
  display: flex;
  height: 7.2rem;
  transition: all ease-in 0.2s;
  width: 7.2rem;
}
.inline-video__play-pause-btn:focus {
  transform: scale(1.1);
}
@media (min-width: 840px) {
  .inline-video__play-pause-btn:hover {
    transform: scale(1.1);
  }
}
@media (max-width: 1024px) {
  .inline-video__btn-container svg {
    height: 3.6rem;
    width: 3.6rem;
  }
  .inline-video__play-pause-btn {
    height: 6.4rem;
    width: 6.4rem;
  }
  .inline-video__content {
    padding: var(--spacing-m);
  }
  .inline-video__description {
    margin: 0 var(--spacing-xs) var(--spacing-xs);
  }
  .inline-video__title {
    font-size: var(--header4);
  }
}
@media (max-width: 840px) {
  .inline-video__figure {
    aspect-ratio: 807/550;
  }
  .inline-video__content {
    inset: var(--spacing-xl) var(--spacing-m);
  }
}
@media (max-width: 640px) {
  .inline-video__figure {
    aspect-ratio: 608/550;
  }
}
@media (max-width: 425px) {
  .inline-video__figure {
    aspect-ratio: 343/550;
  }
}

.media-promo {
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
  height: calc(100dvh - (var(--nav-height-desktop) + var(--lozenge-height)));
  max-height: 58rem;
}
.media-promo--is-hero {
  max-height: 56.9rem;
}
.media-promo--is-hero .media-promo__content {
  margin-top: 8.8rem;
}
.media-promo--video-playing .media-promo__content {
  opacity: 0;
}
.media-promo__background {
  inset: 0;
  position: absolute;
}
.media-promo__background::before {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.37) 53.11%, rgba(0, 0, 0, 0.27) 62.77%, rgba(0, 0, 0, 0) 92.52%) no-repeat;
  content: "";
  inset: 0;
  position: absolute;
  z-index: 10;
}
.media-promo__background::after {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
  content: "";
  height: 29.1rem;
  inset: auto 0 0;
  position: absolute;
  z-index: 10;
}
.media-promo__background--mobile {
  display: none;
}
.media-promo__partner-logo {
  max-width: 100%;
  padding: var(--spacing-xxl) var(--spacing-l);
  position: absolute;
  z-index: 10;
}
.media-promo__content {
  display: flex;
  z-index: 10;
  position: absolute;
  inset: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 89rem;
  margin: auto;
  transition: all ease-out 1s;
}
.media-promo__title {
  color: var(--tertiary);
  font-weight: var(--bold);
  font-size: var(--header1);
  line-height: 1;
  margin-bottom: var(--spacing-s);
  text-align: center;
  width: 100%;
}
.media-promo__description {
  color: var(--white);
  font-weight: var(--bold);
  line-height: 1.5;
  max-width: 65.8rem;
  text-align: center;
  width: 100%;
}
.media-promo__figure {
  all: unset;
  inset: 0;
  position: absolute;
}
.media-promo__figure::after {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
  content: "";
  height: 29.1rem;
  inset: auto 0 0;
  position: absolute;
}
.media-promo__video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.media-promo__btn-container {
  position: absolute;
  bottom: 2rem;
  right: 0;
  left: 0;
  margin: auto;
  z-index: 20;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
}
.media-promo__btn-container svg {
  fill: var(--white);
  pointer-events: none;
  width: 3rem;
  height: 3rem;
}
.media-promo__sound-btn {
  position: relative;
  transition: transform ease-in 0.2s;
}
.media-promo__sound-btn:focus {
  transform: scale(1.1);
}
@media (min-width: 840px) {
  .media-promo__sound-btn:hover {
    transform: scale(1.1);
  }
}
.media-promo__sound-btn--muted::after, .media-promo__sound-btn--muted::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: -0.3rem;
  width: 3.7rem;
  height: 0.3rem;
  margin: auto;
  background: var(--white);
  transform: rotate(45deg);
}
.media-promo__sound-btn--muted::after {
  transform: rotate(-45deg);
}
.media-promo__icon-pause {
  display: none;
}
.media-promo__play-pause-btn {
  transition: transform ease-in 0.2s;
}
.media-promo__play-pause-btn:focus {
  transform: scale(1.1);
}
@media (min-width: 840px) {
  .media-promo__play-pause-btn:hover {
    transform: scale(1.1);
  }
}
.media-promo__play-pause-btn--play .media-promo__icon-pause {
  display: inherit;
}
.media-promo__play-pause-btn--play .media-promo__icon-play {
  display: none;
}

@media (max-width: 840px) {
  .media-promo {
    height: calc(100dvh - var(--nav-height-mobile));
  }
  .media-promo--is-hero {
    max-height: 65rem;
  }
  .media-promo--is-hero .media-promo__content {
    margin-top: 7.8rem;
  }
  .media-promo__partner-logo {
    padding: var(--spacing-xl) var(--spacing-s);
  }
  .media-promo__content {
    padding: var(--spacing-m);
  }
  .media-promo__title {
    font-size: var(--header2);
  }
  .media-promo__background--desktop {
    display: none;
  }
  .media-promo__background--mobile {
    display: unset;
  }
}
/* stylelint-disable selector-max-specificity */
.main-navigation {
  height: 6.4rem;
  padding: 0 var(--spacing-m);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--white);
  transition: height 0.25s ease;
  will-change: height;
  z-index: 100;
  width: 100%;
}
@media (min-width: 840px) {
  .main-navigation {
    border: none;
    height: auto;
    justify-content: flex-start;
  }
}
@media (min-width: 1200px) {
  .main-navigation {
    padding: 0 var(--spacing-xxl);
  }
}
.main-navigation__menu-link {
  height: 4rem;
  margin-right: auto;
  position: relative;
  transition: width 0.25s ease;
  width: 14rem;
  z-index: 106;
}
.main-navigation__close-bar {
  background-color: var(--white);
  display: flex;
  height: 6.4rem;
  justify-content: flex-end;
  left: 0;
  padding: 0 var(--spacing-m);
  position: absolute;
  right: 0;
  top: calc(-1 * var(--nav-height-mobile));
}
.main-navigation__menu-trigger {
  all: unset;
  align-items: center;
  display: flex;
  font-weight: var(--medium);
  line-height: 1;
}
.main-navigation__close-icon, .main-navigation__open-icon {
  height: 2.4rem;
  margin-left: 0.4rem;
  width: 2.4rem;
}
.main-navigation__pos {
  align-items: center;
  background-color: var(--expediaBlue);
  display: flex;
  inset: 100% 0 auto;
  font-size: var(--link);
  height: 4.8rem;
  padding: 0 var(--spacing-m);
  position: absolute;
  text-align: center;
  z-index: 11;
}
.main-navigation__pos svg {
  fill: var(--white);
  height: 2rem;
  margin: 0 0 0.9rem 0.2rem;
  width: 2rem;
}
@media (min-width: 840px) {
  .main-navigation__pos {
    padding: 0 var(--spacing-xxl);
  }
}
.main-navigation__nav {
  background-color: var(--primary);
  display: flex;
  flex-direction: column;
  left: 0;
  position: fixed;
  top: var(--nav-height-mobile);
  width: 100%;
  height: calc(100dvh - var(--nav-height-mobile));
  flex: 1;
  text-decoration: none;
  transition: transform 0.25s ease;
  transform: translateX(100%);
  will-change: transform;
  z-index: 105;
}
.is-showing .main-navigation__nav {
  transform: translateX(0);
}
@media (min-width: 840px) {
  .main-navigation__nav {
    flex-direction: row;
    margin-left: 10.3rem;
    height: var(--nav-height-desktop);
    position: relative;
    top: auto;
    transform: none;
  }
}
.main-navigation__nav .linked-list {
  display: block;
}
@media (min-width: 840px) {
  .main-navigation__nav .linked-list {
    display: flex;
  }
}
.main-navigation__nav .linked-list__link, .main-navigation__nav .linked-list__dropdown-label {
  align-items: center;
  color: var(--text);
  cursor: pointer;
  display: flex;
  font-size: var(--default);
  font-weight: var(--bold);
  justify-content: center;
  line-height: 1.5;
  padding: 1.6rem 0;
  text-align: center;
  width: 100%;
}
.main-navigation__nav .linked-list__link:focus, .main-navigation__nav .linked-list__dropdown-label:focus {
  text-decoration: none;
}
@media (min-width: 840px) {
  .main-navigation__nav .linked-list__link:hover, .main-navigation__nav .linked-list__dropdown-label:hover {
    text-decoration: none;
  }
}
@media (min-width: 840px) {
  .main-navigation__nav .linked-list__link, .main-navigation__nav .linked-list__dropdown-label {
    margin: 0;
    padding: 3.2rem 0;
  }
}
.main-navigation__nav .linked-list > .linked-list__item {
  cursor: pointer;
  display: flex;
  width: 100%;
  justify-content: space-between;
  position: relative;
}
.main-navigation__nav .linked-list > .linked-list__item.has-children {
  flex-direction: column;
}
.main-navigation__nav .linked-list > .linked-list__item.is-active .linked-list__dropdown-label {
  background-color: var(--secondary);
  color: var(--white);
}
.main-navigation__nav .linked-list > .linked-list__item.is-active .linked-list__dropdown-label svg {
  fill: var(--white);
}
.main-navigation__nav .linked-list > .linked-list__item:last-child {
  margin-right: 0;
}
.main-navigation__nav .linked-list > .linked-list__item::after {
  background-color: var(--secondary);
  content: "";
  height: 0.4rem;
  inset: auto 0 0;
  opacity: 0;
  position: absolute;
}
.main-navigation__nav .linked-list > .linked-list__item:focus::after {
  opacity: 1;
}
.main-navigation__nav .linked-list > .linked-list__item:focus .linked-list__dropdown {
  opacity: 1;
  transform: translateX(-50%) scale(1);
}
.main-navigation__nav .linked-list > .linked-list__item:focus .linked-list__dropdown-icon {
  transform: rotate(180deg);
}
@media (min-width: 840px) {
  .main-navigation__nav .linked-list > .linked-list__item:hover::after {
    opacity: 1;
  }
  .main-navigation__nav .linked-list > .linked-list__item:hover .linked-list__dropdown {
    opacity: 1;
    transform: translateX(-50%) scale(1);
  }
  .main-navigation__nav .linked-list > .linked-list__item:hover .linked-list__dropdown-icon {
    transform: rotate(180deg);
  }
}
@media (min-width: 840px) {
  .main-navigation__nav .linked-list > .linked-list__item {
    min-width: 16.6rem;
    position: relative;
    transition: padding 0.25s ease;
    z-index: 100;
  }
  .main-navigation__nav .linked-list > .linked-list__item.has-children {
    flex-direction: row;
  }
}
.main-navigation__nav .linked-list__dropdown {
  all: unset;
  left: 50%;
  overflow: hidden;
  top: 100%;
  opacity: 0;
  position: relative;
  transform-origin: 5rem 0;
  transform: translateX(-50%);
  max-height: 0;
  transition: max-height 0.25s, opacity 0.25s ease;
  width: 27.5rem;
}
.main-navigation__nav .linked-list__dropdown .linked-list__link {
  display: block;
  font-weight: var(--light);
  font-size: inherit;
  line-height: 1.5;
  padding: 2.4rem 2.5rem;
  transition: background-color 0.25s ease;
}
.main-navigation__nav .linked-list__dropdown .linked-list__link:focus {
  color: var(--white);
  background-color: var(--secondary);
  text-decoration: none;
}
@media (min-width: 840px) {
  .main-navigation__nav .linked-list__dropdown .linked-list__link:hover {
    color: var(--white);
    background-color: var(--secondary);
    text-decoration: none;
  }
}
@media (min-width: 840px) {
  .main-navigation__nav .linked-list__dropdown {
    background: var(--white);
    transform: scale(0);
    transition: transform 0.25s ease, opacity 0.25s ease;
    max-height: initial;
    padding: 0.8rem;
    position: absolute;
  }
  .main-navigation__nav .linked-list__dropdown .linked-list__link {
    font-weight: var(--regular);
  }
}
@media (max-width: 840px) {
  .main-navigation__nav .linked-list .is-active .linked-list__dropdown {
    opacity: 1;
    max-height: initial;
  }
  .main-navigation__nav .linked-list .is-active .linked-list__dropdown-icon {
    transform: rotate(180deg);
  }
}
.main-navigation__nav .linked-list__dropdown-icon {
  height: 2.4rem;
  position: absolute;
  right: 1.2rem;
  transition: transform 0.25s ease;
  width: 2.4rem;
}
@media (min-width: 840px) {
  .main-navigation__nav .linked-list__dropdown-icon {
    align-self: center;
    margin: 0 0 0 0.6rem;
    position: unset;
  }
}
.main-navigation__right-content {
  display: flex;
  align-items: center;
  margin-left: auto;
}
.main-navigation__pos-container {
  align-items: flex-end;
  display: flex;
  position: relative;
}
.main-navigation__pos-container:hover .main-navigation__pos-info {
  transform: scale(1);
}
.main-navigation__pos-link {
  height: 4.5rem;
  width: 8.5rem;
}
.main-navigation__logo {
  height: 100%;
  width: 100%;
}
.main-navigation__pos-info {
  color: var(--white);
  position: absolute;
  left: 0;
  top: var(--lozenge-height);
  width: 32rem;
  padding: 1.6rem;
  background-color: var(--expediaBlue);
  border-radius: 1.2rem;
  box-shadow: 0 8px 16px 0 rgba(157, 158, 161, 0.24);
  white-space: normal;
  font-weight: var(--light);
  text-align: center;
  transform: scale(0);
  transform-origin: 0 0;
  transition: 0.3s transform ease;
}
.main-navigation__cta {
  order: 1;
  margin: auto var(--spacing-s) var(--spacing-s);
}
@media (min-width: 840px) {
  .main-navigation__cta {
    display: inline-block;
    margin: 0;
  }
}

.quote {
  background-color: var(--secondary-light);
  position: relative;
}
.quote__background {
  inset: 0;
  position: absolute;
}
.quote__background::after {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
  content: "";
  inset: 0;
  position: absolute;
}
.quote__content {
  color: var(--white);
  max-width: 87rem;
  text-align: center;
}
.quote__title {
  color: var(--white);
  line-height: 1;
}
.quote__title::before {
  content: "“ ";
}
.quote__title::after {
  content: " ”";
}
@media (max-width: 840px) {
  .quote__title {
    font-size: var(--header5);
    line-height: 1.2;
    margin-bottom: var(--spacing-s);
  }
}
.quote__author {
  font-weight: var(--bold);
  opacity: 0.7;
}

/* UTILITIES
========================================================================== */
/* stylelint-disable declaration-no-important  */
[data-theme=light] .u-dark-theme {
  display: none;
}

[data-theme=dark] .u-light-theme {
  display: none;
}

.u-hide {
  display: none !important;
}

.u-show {
  display: block !important;
}

.u-overflow-hidden {
  overflow: hidden;
}

.u-visually-hidden {
  visibility: hidden;
}

.u-observed {
  min-height: 1px;
  min-width: 1px;
}

.u-screen-reader {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.u-no-font {
  font-size: 0;
}

.u-text-upper {
  text-transform: uppercase !important;
}

.u-no-scroll {
  overflow: hidden;
  margin: 0;
  touch-action: none;
}

@media (max-width: 1440px) {
  .u-hide-wide {
    display: none !important;
  }
}
@media (max-width: 1200px) {
  .u-hide-desktop {
    display: none;
  }
}
@media (min-width: 1200px) {
  .u-show-desktop {
    display: none;
  }
}
@media (max-width: 1024px) {
  .u-hide-desktop-small {
    display: none;
  }
}
@media (min-width: 1024px) {
  .u-show-desktop-small {
    display: none;
  }
}
@media (max-width: 840px) {
  .u-hide-tablet {
    display: none !important;
  }
}
@media (min-width: 840px) {
  .u-show-tablet {
    display: none !important;
  }
}
@media (max-width: 640px) {
  .u-hide-phablet {
    display: none !important;
  }
}
@media (min-width: 640px) {
  .u-show-phablet {
    display: none !important;
  }
}
@media (max-width: 1200px) {
  .u-hide-desktop {
    display: none !important;
  }
}
@media (min-width: 425px) {
  .u-show-mobile {
    display: none;
  }
}
@media (max-width: 425px) {
  .u-hide-mobile {
    display: none;
  }
}
@media (min-width: 840px) {
  .u-show-until-tablet {
    display: none;
  }
}
/* stylelint-enable */
/* VENDORS
========================================================================== */
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow, .react-datepicker__navigation-icon::before {
  border-color: #ccc;
  border-style: solid;
  border-width: 3px 3px 0 0;
  content: "";
  display: block;
  height: 9px;
  position: absolute;
  top: 6px;
  width: 9px;
}

.react-datepicker-wrapper {
  display: inline-block;
  padding: 0;
  border: 0;
}

.react-datepicker {
  font-family: "Helvetica Neue", helvetica, arial, sans-serif;
  font-size: 0.8rem;
  background-color: #fff;
  color: #000;
  border: 1px solid #aeaeae;
  border-radius: 0.3rem;
  display: inline-block;
  position: relative;
  line-height: initial;
}

.react-datepicker--time-only .react-datepicker__time-container {
  border-left: 0;
}

.react-datepicker--time-only .react-datepicker__time,
.react-datepicker--time-only .react-datepicker__time-box {
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.react-datepicker-popper {
  z-index: 1;
  line-height: 0;
}

.react-datepicker-popper .react-datepicker__triangle {
  stroke: #aeaeae;
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle {
  fill: #f0f0f0;
  color: #f0f0f0;
}

.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle {
  fill: #fff;
  color: #fff;
}

.react-datepicker__header {
  text-align: center;
  background-color: #f0f0f0;
  border-bottom: 1px solid #aeaeae;
  border-top-left-radius: 0.3rem;
  padding: 8px 0;
  position: relative;
}

.react-datepicker__header--time {
  padding-bottom: 8px;
  padding-left: 5px;
  padding-right: 5px;
}

.react-datepicker__header--time:not(.react-datepicker__header--time--only) {
  border-top-left-radius: 0;
}

.react-datepicker__header:not(.react-datepicker__header--has-time-select) {
  border-top-right-radius: 0.3rem;
}

.react-datepicker__year-dropdown-container--select,
.react-datepicker__month-dropdown-container--select,
.react-datepicker__month-year-dropdown-container--select,
.react-datepicker__year-dropdown-container--scroll,
.react-datepicker__month-dropdown-container--scroll,
.react-datepicker__month-year-dropdown-container--scroll {
  display: inline-block;
  margin: 0 15px;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  margin-top: 0;
  color: #000;
  font-weight: bold;
  font-size: 0.944rem;
}

.react-datepicker-time__header {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.react-datepicker__navigation {
  align-items: center;
  background: none;
  display: flex;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  position: absolute;
  top: 2px;
  padding: 0;
  border: none;
  z-index: 1;
  height: 32px;
  width: 32px;
  text-indent: -999em;
  overflow: hidden;
}

.react-datepicker__navigation--previous {
  left: 2px;
}

.react-datepicker__navigation--next {
  right: 2px;
}

.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
  right: 85px;
}

.react-datepicker__navigation--years {
  position: relative;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.react-datepicker__navigation--years-previous {
  top: 4px;
}

.react-datepicker__navigation--years-upcoming {
  top: -4px;
}

.react-datepicker__navigation:hover *::before {
  border-color: #a6a6a6;
}

.react-datepicker__navigation-icon {
  position: relative;
  top: -1px;
  font-size: 20px;
  width: 0;
}

.react-datepicker__navigation-icon--next {
  left: -2px;
}

.react-datepicker__navigation-icon--next::before {
  transform: rotate(45deg);
  left: -7px;
}

.react-datepicker__navigation-icon--previous {
  right: -2px;
}

.react-datepicker__navigation-icon--previous::before {
  transform: rotate(225deg);
  right: -7px;
}

.react-datepicker__month-container {
  float: left;
}

.react-datepicker__year {
  margin: 0.4rem;
  text-align: center;
}

.react-datepicker__year-wrapper {
  display: flex;
  flex-wrap: wrap;
  max-width: 180px;
}

.react-datepicker__year .react-datepicker__year-text {
  display: inline-block;
  width: 4rem;
  margin: 2px;
}

.react-datepicker__month {
  margin: 0.4rem;
  text-align: center;
}

.react-datepicker__month .react-datepicker__month-text,
.react-datepicker__month .react-datepicker__quarter-text {
  display: inline-block;
  width: 4rem;
  margin: 2px;
}

.react-datepicker__input-time-container {
  clear: both;
  width: 100%;
  float: left;
  margin: 5px 0 10px 15px;
  text-align: left;
}

.react-datepicker__input-time-container .react-datepicker-time__caption {
  display: inline-block;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container {
  display: inline-block;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input {
  display: inline-block;
  margin-left: 10px;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input {
  width: auto;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type=time]::-webkit-inner-spin-button,
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type=time]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type=time] {
  -moz-appearance: textfield;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__delimiter {
  margin-left: 5px;
  display: inline-block;
}

.react-datepicker__time-container {
  float: right;
  border-left: 1px solid #aeaeae;
  width: 85px;
}

.react-datepicker__time-container--with-today-button {
  display: inline;
  border: 1px solid #aeaeae;
  border-radius: 0.3rem;
  position: absolute;
  right: -87px;
  top: 0;
}

.react-datepicker__time-container .react-datepicker__time {
  position: relative;
  background: white;
  border-bottom-right-radius: 0.3rem;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
  width: 85px;
  overflow-x: hidden;
  margin: 0 auto;
  text-align: center;
  border-bottom-right-radius: 0.3rem;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
  list-style: none;
  margin: 0;
  height: calc(195px + 0.85rem);
  overflow-y: scroll;
  padding-right: 0;
  padding-left: 0;
  width: 100%;
  box-sizing: content-box;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
  height: 30px;
  padding: 5px 10px;
  white-space: nowrap;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {
  cursor: pointer;
  background-color: #f0f0f0;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
  background-color: #216ba5;
  color: white;
  font-weight: bold;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover {
  background-color: #216ba5;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled {
  color: #ccc;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled:hover {
  cursor: default;
  background-color: transparent;
}

.react-datepicker__week-number {
  color: #ccc;
  display: inline-block;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.166rem;
}

.react-datepicker__week-number.react-datepicker__week-number--clickable {
  cursor: pointer;
}

.react-datepicker__week-number.react-datepicker__week-number--clickable:not(.react-datepicker__week-number--selected,
.react-datepicker__week-number--keyboard-selected):hover {
  border-radius: 0.3rem;
  background-color: #f0f0f0;
}

.react-datepicker__week-number--selected {
  border-radius: 0.3rem;
  background-color: #216ba5;
  color: #fff;
}

.react-datepicker__week-number--selected:hover {
  background-color: #1d5d90;
}

.react-datepicker__week-number--keyboard-selected {
  border-radius: 0.3rem;
  background-color: #2a87d0;
  color: #fff;
}

.react-datepicker__week-number--keyboard-selected:hover {
  background-color: #1d5d90;
}

.react-datepicker__day-names {
  white-space: nowrap;
  margin-bottom: -8px;
}

.react-datepicker__week {
  white-space: nowrap;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: #000;
  display: inline-block;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.166rem;
}

.react-datepicker__day,
.react-datepicker__month-text,
.react-datepicker__quarter-text,
.react-datepicker__year-text {
  cursor: pointer;
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
  border-radius: 0.3rem;
  background-color: #f0f0f0;
}

.react-datepicker__day--today,
.react-datepicker__month-text--today,
.react-datepicker__quarter-text--today,
.react-datepicker__year-text--today {
  font-weight: bold;
}

.react-datepicker__day--highlighted,
.react-datepicker__month-text--highlighted,
.react-datepicker__quarter-text--highlighted,
.react-datepicker__year-text--highlighted {
  border-radius: 0.3rem;
  background-color: #3dcc4a;
  color: #fff;
}

.react-datepicker__day--highlighted:hover,
.react-datepicker__month-text--highlighted:hover,
.react-datepicker__quarter-text--highlighted:hover,
.react-datepicker__year-text--highlighted:hover {
  background-color: #32be3f;
}

.react-datepicker__day--highlighted-custom-1,
.react-datepicker__month-text--highlighted-custom-1,
.react-datepicker__quarter-text--highlighted-custom-1,
.react-datepicker__year-text--highlighted-custom-1 {
  color: magenta;
}

.react-datepicker__day--highlighted-custom-2,
.react-datepicker__month-text--highlighted-custom-2,
.react-datepicker__quarter-text--highlighted-custom-2,
.react-datepicker__year-text--highlighted-custom-2 {
  color: green;
}

.react-datepicker__day--holidays,
.react-datepicker__month-text--holidays,
.react-datepicker__quarter-text--holidays,
.react-datepicker__year-text--holidays {
  position: relative;
  border-radius: 0.3rem;
  background-color: #ff6803;
  color: #fff;
}

.react-datepicker__day--holidays .overlay,
.react-datepicker__month-text--holidays .overlay,
.react-datepicker__quarter-text--holidays .overlay,
.react-datepicker__year-text--holidays .overlay {
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 4px;
  border-radius: 4px;
  white-space: nowrap;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.3s ease-in-out;
}

.react-datepicker__day--holidays:hover,
.react-datepicker__month-text--holidays:hover,
.react-datepicker__quarter-text--holidays:hover,
.react-datepicker__year-text--holidays:hover {
  background-color: #cf5300;
}

.react-datepicker__day--holidays:hover .overlay,
.react-datepicker__month-text--holidays:hover .overlay,
.react-datepicker__quarter-text--holidays:hover .overlay,
.react-datepicker__year-text--holidays:hover .overlay {
  visibility: visible;
  opacity: 1;
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  border-radius: 0.3rem;
  background-color: #216ba5;
  color: #fff;
}

.react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover {
  background-color: #1d5d90;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  border-radius: 0.3rem;
  background-color: #bad9f1;
  color: rgb(0, 0, 0);
}

.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover,
.react-datepicker__quarter-text--keyboard-selected:hover,
.react-datepicker__year-text--keyboard-selected:hover {
  background-color: #1d5d90;
}

.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--in-range),
.react-datepicker__month-text--in-selecting-range:not(.react-datepicker__day--in-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--in-range),
.react-datepicker__quarter-text--in-selecting-range:not(.react-datepicker__day--in-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--in-range),
.react-datepicker__year-text--in-selecting-range:not(.react-datepicker__day--in-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--in-range) {
  background-color: rgba(33, 107, 165, 0.5);
}

.react-datepicker__month--selecting-range .react-datepicker__day--in-range:not(.react-datepicker__day--in-selecting-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__year-text--in-selecting-range),
.react-datepicker__year--selecting-range .react-datepicker__day--in-range:not(.react-datepicker__day--in-selecting-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__year-text--in-selecting-range),
.react-datepicker__month--selecting-range .react-datepicker__month-text--in-range:not(.react-datepicker__day--in-selecting-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__year-text--in-selecting-range),
.react-datepicker__year--selecting-range .react-datepicker__month-text--in-range:not(.react-datepicker__day--in-selecting-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__year-text--in-selecting-range),
.react-datepicker__month--selecting-range .react-datepicker__quarter-text--in-range:not(.react-datepicker__day--in-selecting-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__year-text--in-selecting-range),
.react-datepicker__year--selecting-range .react-datepicker__quarter-text--in-range:not(.react-datepicker__day--in-selecting-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__year-text--in-selecting-range),
.react-datepicker__month--selecting-range .react-datepicker__year-text--in-range:not(.react-datepicker__day--in-selecting-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__year-text--in-selecting-range),
.react-datepicker__year--selecting-range .react-datepicker__year-text--in-range:not(.react-datepicker__day--in-selecting-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__year-text--in-selecting-range) {
  background-color: #f0f0f0;
  color: #000;
}

.react-datepicker__day--disabled,
.react-datepicker__month-text--disabled,
.react-datepicker__quarter-text--disabled,
.react-datepicker__year-text--disabled {
  cursor: default;
  color: #ccc;
}

.react-datepicker__day--disabled:hover,
.react-datepicker__month-text--disabled:hover,
.react-datepicker__quarter-text--disabled:hover,
.react-datepicker__year-text--disabled:hover {
  background-color: transparent;
}

.react-datepicker__day--disabled .overlay,
.react-datepicker__month-text--disabled .overlay,
.react-datepicker__quarter-text--disabled .overlay,
.react-datepicker__year-text--disabled .overlay {
  position: absolute;
  bottom: 70%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 4px;
  border-radius: 4px;
  white-space: nowrap;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.3s ease-in-out;
}

.react-datepicker__input-container {
  position: relative;
  display: inline-block;
  width: 100%;
}

.react-datepicker__input-container .react-datepicker__calendar-icon {
  position: absolute;
  padding: 0.5rem;
  box-sizing: content-box;
}

.react-datepicker__view-calendar-icon input {
  padding: 6px 10px 5px 25px;
}

.react-datepicker__year-read-view,
.react-datepicker__month-read-view,
.react-datepicker__month-year-read-view {
  border: 1px solid transparent;
  border-radius: 0.3rem;
  position: relative;
}

.react-datepicker__year-read-view:hover,
.react-datepicker__month-read-view:hover,
.react-datepicker__month-year-read-view:hover {
  cursor: pointer;
}

.react-datepicker__year-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__year-read-view:hover .react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view:hover .react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-year-read-view:hover .react-datepicker__month-read-view--down-arrow {
  border-top-color: #b3b3b3;
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  transform: rotate(135deg);
  right: -16px;
  top: 0;
}

.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown,
.react-datepicker__month-year-dropdown {
  background-color: #f0f0f0;
  position: absolute;
  width: 50%;
  left: 25%;
  top: 30px;
  z-index: 1;
  text-align: center;
  border-radius: 0.3rem;
  border: 1px solid #aeaeae;
}

.react-datepicker__year-dropdown:hover,
.react-datepicker__month-dropdown:hover,
.react-datepicker__month-year-dropdown:hover {
  cursor: pointer;
}

.react-datepicker__year-dropdown--scrollable,
.react-datepicker__month-dropdown--scrollable,
.react-datepicker__month-year-dropdown--scrollable {
  height: 150px;
  overflow-y: scroll;
}

.react-datepicker__year-option,
.react-datepicker__month-option,
.react-datepicker__month-year-option {
  line-height: 20px;
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.react-datepicker__year-option:first-of-type,
.react-datepicker__month-option:first-of-type,
.react-datepicker__month-year-option:first-of-type {
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}

.react-datepicker__year-option:last-of-type,
.react-datepicker__month-option:last-of-type,
.react-datepicker__month-year-option:last-of-type {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.react-datepicker__year-option:hover,
.react-datepicker__month-option:hover,
.react-datepicker__month-year-option:hover {
  background-color: #ccc;
}

.react-datepicker__year-option:hover .react-datepicker__navigation--years-upcoming,
.react-datepicker__month-option:hover .react-datepicker__navigation--years-upcoming,
.react-datepicker__month-year-option:hover .react-datepicker__navigation--years-upcoming {
  border-bottom-color: #b3b3b3;
}

.react-datepicker__year-option:hover .react-datepicker__navigation--years-previous,
.react-datepicker__month-option:hover .react-datepicker__navigation--years-previous,
.react-datepicker__month-year-option:hover .react-datepicker__navigation--years-previous {
  border-top-color: #b3b3b3;
}

.react-datepicker__year-option--selected,
.react-datepicker__month-option--selected,
.react-datepicker__month-year-option--selected {
  position: absolute;
  left: 15px;
}

.react-datepicker__close-icon {
  cursor: pointer;
  background-color: transparent;
  border: 0;
  outline: 0;
  padding: 0 6px 0 0;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  display: table-cell;
  vertical-align: middle;
}

.react-datepicker__close-icon::after {
  cursor: pointer;
  background-color: #216ba5;
  color: #fff;
  border-radius: 50%;
  height: 16px;
  width: 16px;
  padding: 2px;
  font-size: 12px;
  line-height: 1;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  content: "×";
}

.react-datepicker__close-icon--disabled {
  cursor: default;
}

.react-datepicker__close-icon--disabled::after {
  cursor: default;
  background-color: #ccc;
}

.react-datepicker__today-button {
  background: #f0f0f0;
  border-top: 1px solid #aeaeae;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  padding: 5px 0;
  clear: left;
}

.react-datepicker__portal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  left: 0;
  top: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  z-index: 2147483647;
}

.react-datepicker__portal .react-datepicker__day-name,
.react-datepicker__portal .react-datepicker__day,
.react-datepicker__portal .react-datepicker__time-name {
  width: 3rem;
  line-height: 3rem;
}

@media (max-width: 400px), (max-height: 550px) {
  .react-datepicker__portal .react-datepicker__day-name,
  .react-datepicker__portal .react-datepicker__day,
  .react-datepicker__portal .react-datepicker__time-name {
    width: 2rem;
    line-height: 2rem;
  }
}
.react-datepicker__portal .react-datepicker__current-month,
.react-datepicker__portal .react-datepicker-time__header {
  font-size: 1.44rem;
}

.react-datepicker__children-container {
  width: 13.8rem;
  margin: 0.4rem;
  padding-right: 0.2rem;
  padding-left: 0.2rem;
  height: auto;
}

.react-datepicker__aria-live {
  position: absolute;
  clip-path: circle(0);
  border: 0;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  width: 1px;
  white-space: nowrap;
}

.react-datepicker__calendar-icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.125em;
}