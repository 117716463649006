.embeddable-photo {
    margin: 0 0 4.8rem;

    &__title {
        font-size: 2rem;
        margin-bottom: 3rem;
    }

    &__figure {
        all: unset;
        position: relative;

        .lazy-image-wrapper {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }
    }

    &__image-wrapper {
        background-color: var(--neutral500);
        border-radius: .8rem;
        border-right: .6rem solid var(--tertiary);
        border-left: .6rem solid var(--tertiary);
        position: relative;

        &::before {
            content: '';
            display: block;
            padding-bottom: calc(9 / 16) * 100%;
        }
    }

    &__caption {
        color: var(--black);
        font-size: var(--meta);
        font-style: italic;
        line-height: 1;
        margin-top: var(--spacing-xs);
        text-align: left;
    }

    &__summary {
        font-style: italic;
        margin-top: 3rem;
    }

    @media (max-width: $break-desktop) {
        &__caption {
            position: static;
            width: auto;
            margin-top: 2rem;
        }
    }

    @media (max-width: $break-tablet) {
        margin: 0 0 var(--spacing-xl);

        &__caption {
            margin-top: $spacing-xxs;
        }
    }
}
