/* stylelint-disable custom-property-pattern */
.banner-simplified {
    background: var(--secondary-light);
    border-top: .1rem solid var(--neutral700);
    border-bottom: .1rem solid var(--neutral700);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 3rem var(--widget-padding-mobile) 3.5rem;
    position: relative;
 
    p, h2 {
        color: var(--lightText);
        font-size: var(--header5);
        font-weight: var(--bold);
        max-width: 84.1rem;
        text-align: center;
        margin: 0;
    }
   
    strong {
        color: var(--tertiary);
    }

    pre {
        color: var(--lightText);
        font-family: inherit;
        font-weight: var(--regular);
        font-size: 1.5rem;
        text-align: center;
        margin-top: 1rem;
        margin-bottom: 0;
    }

    .button {
        border: 0;
        background-color: var(--tertiary);
        color: var(--neutral900);
        margin-top: 1.4rem;

        &:hover {
            background-color: var(--white);
        }
    }
};