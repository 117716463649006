.media-promo {
    $this: &;
    align-items: center;
    display: flex;
    justify-content: center;
    position: relative;
    width: 100%;
    height: calc(100dvh - calc(var(--nav-height-desktop) + var(--lozenge-height)));
    max-height: 58rem;;

    &--is-hero {
        max-height: 56.9rem;

        #{$this}__content {
            margin-top: 8.8rem;
        }
    }

    &--video-playing {

        #{$this}__content {
            opacity: 0;
        }
    }

    &__background {
        inset: 0;
        position: absolute;
        
        &::before {
            background: linear-gradient(0deg, rgb(0 0 0 / 37%) 53.11%, rgb(0 0 0 / 27%) 62.77%, rgb(0 0 0 / 0%) 92.52%) no-repeat;
            content: '';
            inset: 0;
            position: absolute;
            z-index: $level-one;
        }
        
        &::after {
            background: linear-gradient(180deg, rgb(0 0 0 / 0%) 0%, rgb(0 0 0 / 40%) 100%);
            content: '';
            height: 29.1rem;
            inset: auto 0 0;
            position: absolute;
            z-index: $level-one;
        }

        &--mobile {
            display: none;
        }
    }

    &__partner-logo {
        max-width: 100%;
        padding: var(--spacing-xxl) var(--spacing-l);
        position: absolute;
        z-index: $level-one;
    }

    &__content {
        display: flex;
        z-index: $level-one;
        position: absolute;
        inset: 0;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 89rem;
        margin: auto;
        transition: all ease-out 1s;
    }

    &__title {
        color: var(--tertiary);
        font-weight: var(--bold);
        font-size: var(--header1);
        line-height: 1;
        margin-bottom: var(--spacing-s);
        text-align: center;
        width: 100%;
    }

    &__description {
        color: var(--white);
        font-weight: var(--bold);
        line-height: 1.5;
        max-width: 65.8rem;
        text-align: center;
        width: 100%;
    }

    &__figure {
        all: unset;
        inset: 0;
        position: absolute;

        &::after {
            background: linear-gradient(180deg, rgb(0 0 0 / 0%) 0%, rgb(0 0 0 / 40%) 100%);
            content: '';
            height: 29.1rem;
            inset: auto 0 0;
            position: absolute;
        }
    }

    &__video {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &__btn-container {
        position: absolute;
        bottom: 2rem;
        right: 0;
        left: 0;
        margin: auto;
        z-index: $level-two;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1.5rem;

        svg {
            fill: var(--white);
            pointer-events: none;
            width: 3rem;
            height: 3rem;
        }
    }

    &__sound-btn {
        position: relative;
        transition: transform ease-in .2s;

        @include hover {
            transform: scale(1.1);
        }

        &--muted {
            &::after,
            &::before {
                content: '';
                position: absolute;
                top: 0;
                bottom: 0;
                left: -.3rem;
                width: 3.7rem;
                height: .3rem;
                margin: auto;
                background: var(--white);
                transform: rotate(45deg);
            }

            &::after {
                transform: rotate(-45deg)
            }
        }
    }

    &__icon-pause {
        display: none;
    }

    &__play-pause-btn {
        transition: transform ease-in .2s;

        @include hover {
            transform: scale(1.1);
        }

        &--play {

            #{$this}__icon-pause {
                display: inherit;
            }

            #{$this}__icon-play {
                display: none;
            }
        }
    }
}

@media (max-width: $break-tablet){
    .media-promo {
        $this: &;
        height: calc(100dvh - var(--nav-height-mobile));

        &--is-hero {
            max-height: 65rem;

            #{$this}__content {
                margin-top: 7.8rem;
            }
        }

        &__partner-logo {
            padding: var(--spacing-xl) var(--spacing-s);
        }

        &__content {
            padding: var(--spacing-m);
        }

        &__title {
            font-size: var(--header2);
        }

        &__background {
            &--desktop {
                display: none;
            }

            &--mobile {
                display: unset;
            }
        }
    }
}
