.inline-video {
    $this: &;
    position: relative;
    width: 100%;

    &::before {
        background-color: var(--tertiary);
        content: '';
        inset: 50% 0 0;
        position: absolute;
    }

    .wrapper {
        margin-bottom: 0;
        position: relative;
    }

    &--video-playing {

        #{$this} {
            &__figure {
                &::before,
                &::after {
                    opacity: 0;
                    pointer-events: none;
                }
            }
            
            &__content,
            &__background,
            &__btn-container {
                opacity: 0;
                pointer-events: none;
            }

            &__video {
                /* stylelint-disable-next-line declaration-no-important */
                opacity: 1 !important;
            }

        }
    }

    &__content {
        align-items: center;
        display: flex;
        flex-direction: column;
        inset: var(--spacing-xxl);
        justify-content: flex-end;
        margin: auto;
        max-width: 64.4rem;
        position: absolute;
        text-align: center;
        transition: all ease-out 1s;
        z-index: $level-one;
    }

    &__title {
        color: var(--white);
    }

    &__description {
        color: var(--white);
        margin-bottom: var(--spacing-xxl);
    }

    &__figure {
        aspect-ratio: 16/9;
        border-radius: 1.2rem;
        margin: 0;
        overflow: hidden;
        position: relative;

        &::before,
        &::after {
            content: '';
            position: absolute;
            transition: opacity ease-out 1s;
            z-index: $level-one;
        }

        &::before {
            background-color: rgba(#000, .3);
            inset: 0;
        }

        &::after {
            background: linear-gradient(180deg, rgb(0 0 0 / 0%) 0%, rgb(0 0 0 / 80%) 100%);
            height: 46.3rem;
            inset: auto 0 0;
        }
    }

    &__video {
        display: block;
        height: 100%;
        object-fit: cover;
        opacity: 0;
        transition: opacity ease-out 1s;
        width: 100%;
    }

    &__btn-container {
        align-items: center;
        display: flex;
        gap: 1.5rem;
        inset: 0;
        justify-content: center;
        margin: auto;
        position: absolute;
        z-index: $level-two;

        svg {
            fill: var(--white);
            height: 4.2rem;
            margin: auto;
            pointer-events: none;
            width: 4.2rem;
        }
    }

    &__icon-pause {
        display: none;
    }

    &__play-pause-btn {
        background-color: var(--secondary);
        border-radius: 1rem;
        display: flex;
        height: 7.2rem;
        transition: all ease-in .2s;
        width: 7.2rem;

        @include hover {
            transform: scale(1.1);
        }
    }

    @media (max-width: $break-desktop-small) {
        &__btn-container {
            svg {
                height: 3.6rem;
                width: 3.6rem;
            }
        }

        &__play-pause-btn {
            height: 6.4rem;
            width: 6.4rem;
        }

        &__content {
            padding: var(--spacing-m);
        }

        &__description {
            margin: 0 var(--spacing-xs)  var(--spacing-xs);
        }

        &__title {
            font-size: var(--header4);
        }
    }
    
    @media (max-width: $break-tablet) {
        &__figure {
            aspect-ratio: 807/550;
        }

        &__content {
            inset: var(--spacing-xl) var(--spacing-m);
        }
    }
    
    @media (max-width: $break-phablet) {
        &__figure {
            aspect-ratio: 608/550;
        }
    }
    
    @media (max-width: $break-mobile) {
        &__figure {
            aspect-ratio: 343/550;
        }
    }
}