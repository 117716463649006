.footer {
    background: var(--black);
    display: flex;
    flex-direction: column;
    padding: 2rem var(--spacing-l);
    z-index: $level-one;

    &__copyright {
        color: var(--white);
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin: 3.2rem 0;

        svg {
            height: 1.6rem;
            margin: 0 .6rem;
            width: 11rem;
        }

        span {
            align-items: center;
            display: flex;
            font-size: var(--tiny);
            font-weight: var(--light);
            line-height: 1.6rem;
            margin: 0;
            white-space: nowrap;

            &:first-child {
                margin-right: .4rem;
            }
        }
    }

    .linked-list {
        display: flex;
        flex-wrap: wrap;
        padding: 0;

        &__item {
            margin-right: 1rem;
            list-style: none;
            display: flex;

            &:not(:first-child) {
                align-items: center;
                gap: 1rem;

                &::before {
                    content: '';
                    display: block;
                    width: .4rem;
                    height: .4rem;
                    border-radius: 50%;
                    background-color: var(--white);
                }
            }
        }

        &__link {
            color: var(--white);
            font-size: var(--tiny);
            font-weight: var(--light);
            line-height: 1.6rem;
            white-space: nowrap;
        }
    }

    &__social {
        display: flex;
        justify-content: flex-start;
        order: -1;
    }

    &__social-link {
        margin: 0 .8rem;

        svg {
            fill: var(--white);
            height: 1.6rem;
            transition: fill .25s ease;
            width: 1.6rem;

            &:hover {
                fill: var(--secondary);
            }
        }

        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            margin-right: 0;
        }
    }

    @media (min-width: $break-phablet) {
        align-items: center;

        &__copyright {
            span:first-child {
                margin-bottom: 0;
            }
        }

        &__social {
            align-items: center;
        }
    }

    @media (min-width: $break-desktop-small) {
        height: 6.4rem;
        flex-direction: row;
        border-top: none;

        &__copyright {
            flex-direction: row;
            margin: 0;

            span:first-child {
                margin: 0;
            }
        }


        .linked-list {
            margin: 0 0 0 9.8rem;
        }

        &__social {
            order: 1;
            margin-left: auto;
        }
    }

    @media (min-width: $break-desktop) {
        padding-left: var(--spacing-xxl);
        padding-right: var(--spacing-xxl);
    }
}