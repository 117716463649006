.static-hero {
    background-color: var(--colorPlaceholder);
    align-items: flex-end;
    display: flex;
    height: 39rem;
    justify-content: center;
    max-width: 100%;
    position: relative;
    width: 100%;

    &::after {
        background: linear-gradient(
            180deg,
            rgb(32 40 67 / 0%) 10%,
            var(--neutral800) 100%
        );
        content: '';
        inset: 0;
        position: absolute;
    }

    &--shareable {
        height: 47rem;
        margin-bottom: 3.2rem;
    }

    &__content {
        padding-bottom: 5rem;
        position: relative;
        text-align: center;
        z-index: 10;
    }

    &__logo {
        margin-bottom: 2rem;
        max-height: 12rem;
    }

    &__description {
        font-weight: var(--light);
        line-height: 1.2;
        margin: 0 auto;
        max-width: 35rem;
        padding: 0 1.2rem;
    }

    @media (min-width: $break-mobile) {
        &__description {
            max-width: 50rem;
        }

        &__logo {
            max-height: 15rem;
        }
    }

    @media (min-width: $break-tablet) {
        height: 40rem;

        &--shareable {
            height: 47rem;
        }

        &__description {
            max-width: 67rem;
        }
    }
}
