.meta-data {
    $this: &;
    display: flex;
    flex-wrap: wrap;
    font-weight: var(--light);
    transition: opacity 0.5s ease-in-out 0.5s;

    &__item {
        align-items: center;
        color: var(--neutral300);
        display: flex;
        font-size: var(--meta);
        font-weight: var(--light);
        margin-bottom: 0.5rem;
        margin-right: 1.2rem;

        svg {
            margin-right: 0.4rem;
            fill: currentcolor;
            height: 1.6rem;
            width: 1.6rem;
        }

        &::before {
            background-color: var(--neutral300);
            border-radius: 100%;
            content: '';
            display: inline-block;
            height: 0.4rem;
            margin-right: 1.2rem;
            width: 0.4rem;
        }

        &:first-child::before {
            content: none;
        }
    }

    &__progress-bar-container {
        background-color: var(--neutral600);
        position: relative;
        height: 0.4rem;
        width: 7rem;
    }

    &__progress-bar {
        background-color: var(--button);
        inset: 0 auto 0 0;
        position: absolute;
    }

    &--inline-video {
        margin: 2.4rem 0 0;
    }

    &--season {
        position: relative;
        margin: 0 0 2.4rem;

        #{$this}__item {
            color: var(--white);
        }
    }

    &--series-promo {
        margin-top: 2.4rem;

        #{$this}__item {
            color: var(--neutral300);
        }
    }

    &--channel-hero {
        margin: 2.4rem 0;

        #{$this}__item {
            color: var(--neutral300);
        }
    }

    @media (min-width: $break-mobile-small) {
        &__progress-bar-container {
            width: 10rem;
        }
    }

    @media (min-width: $break-desktop-small) {
        &__progress-bar-container {
            width: 14rem;
        }

        &--series-promo {
            #{$this}__item {
                color: var(--white);
            }
        }
    }
}
