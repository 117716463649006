.theme-mood {
    &-hibiscus {
        --mood-primary: #ae2375;
        --mood-secondary: var(--white, #fff);
    }

    &-tan {
        --mood-primary: #cfac7c;
        --mood-secondary: var(--neutral900, #141d38);
    }

    &-elm {
        --mood-primary: #1b806d;
        --mood-secondary: var(--white, #fff);
    }

    &-bondi-blue {
        --mood-primary: #00a4af;
        --mood-secondary: var(--white, #fff);
    }

    &-flush-orange {
        --mood-primary: #ff8200;
        --mood-secondary: var(--neutral900, #141d38);
    }
}
