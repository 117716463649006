.article-related {
    margin: 0 auto;
    position: relative;
    padding: 0;

    @media (min-width: $break-tablet) {
        max-width: 64.8rem;
        overflow: hidden;
    }

    &__list-item {
        display: block;
        flex: 0 0 auto;
        margin: 0 .6rem;
        list-style: none;
        scroll-snap-align: center;
        position: relative;
        max-width: 20.8rem;
        width: 100%;
    }

    &__img {
        aspect-ratio: 208/117;
        border-radius: 1.2rem;
        overflow: hidden;
        position: relative;

        &::after {
            background: linear-gradient(180deg, rgb(22 26 39 / 0%) 0%, rgb(22 26 39 / 85%) 100%);
            content: '';
            height: 3.6rem;
            inset: auto 0 0;
            position: absolute;
        }
    }

    hr {
        all: unset;
        display: block;
        background-color: var(--secondary-light);
        height: .1rem;
        margin-bottom: 1.2rem;
        opacity: .5;
        width: 4rem;
    }

    &__read-time {
        color: var(--neutral500);
        display: flex;
        font-size: var(--meta);
        margin-top: 1.2rem;

        svg {
            fill: var(--neutral500);
            height: 1.6rem;
            margin-right: .4rem;
            width: 1.6rem;
        }
    }

    &__item-title {
        @include line-clamp(2);
        color: var(--neutral900);
        line-height: 1.5;
        height: 4.8rem;
        margin: .8rem 0 1.2rem;
    }

    &__item-description {
        @include line-clamp(3);
        color: var(--neutral900);
        font-size: var(--meta);
        font-weight: var(--regular);
        margin: 0;
    }
}
