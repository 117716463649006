.quote {
    background-color: var(--secondary-light);
    position: relative;

    &__background {
        inset: 0;
        position: absolute;

        &::after {
            background: linear-gradient(180deg, rgb(0 0 0 / 0%) 0%, rgb(0 0 0 / 80%) 100%);
            content: '';
            inset: 0;
            position: absolute;
        }
    }

    &__content {
        color: var(--white);
        max-width: 87rem;
        text-align: center;
    }

    &__title {
        color: var(--white);
        line-height: 1;

        &::before {
            content: '“ ';
        }

        &::after {
            content: ' ”';
        }

        @media (max-width: $break-tablet) {
            font-size: var(--header5);
            line-height: 1.2;
            margin-bottom: var(--spacing-s);
        }
    }

    &__author {
        font-weight: var(--bold);
        opacity: .7;
    }
}