html {
    max-width: 100%;
    font-size: 62.5%;
    height: 100%;
    overflow-x: hidden;

    @media (min-width: $break-tablet) {
        height: auto;
    }
}

body {
    --white: #fff;
    --black: #000;
    --logoText: #fff;
    --neutral50: #F8F5F4;
    --neutral100: #f0ebe8;
    --neutral200: #d7d4d2;
    --neutral300: #b9b6b8;
    --neutral400: #94949e;
    --neutral500: #707070;
    --neutral600: #575B6E;
    --neutral700: #343B53;
    --neutral800: #1E2842;
    --neutral900: #141D38;
    --neutral1000: #161a27;
    --neutral900light: #141D38;
    --accentRed: #EB5433;
    --accentRedDark: #ca2e2b;
    --accentGreen: #0D7066;
    --accentGreenDark: #075758;
    --accentBlue: #4E78E6;
    --accentBlueDark: #3662d8;
    --expediaBlue: #202843;
    --rgbaNeutral800: 32, 40, 67;
    --rgbaNeutral900: 22, 26, 39;
    --primary: var(--white);
    --primaryDark: var(--neutral900);
    --primaryDarker: #274491;
    --secondary-light: #0182C5;
    --secondary: #3662D8;
    --secondaryDark: #ffb64c;
    --secondaryDarker: #daa52c;
    --tertiary: #FFDF00;
    --text: var(--black);
    --lightText: #F8F5F4;
    --button: var(--secondary);
    --buttonDisabled: var(--neutral400);
    --buttonHovered: var(--white);
    --socialButton: var(--lightText);
    --imageBackground: #161a27;
    --sliderBar: #ffc94c;
    --vjsSelectedText: #202843;
    --yellow: #ffc94c;
    --yellowDark: #ffba19;
    --rgbaSecondary: 255, 201, 76;
    --light: 300;
    --regular: 400;
    --medium: 500;
    --bold: 700;
    --extra-bold: 800;
    --aspect16-10: 62.5%; // 16:10
    --aspect16-9: 56.25%; // 16:9
    --aspect1-1: 100%; // 1:1
    --widget-width: 142.4rem;
    --widget-width-narrow: 69.6rem;
    --widget-padding-mobile: 1.6rem;
    --widget-padding-desktop: 2.4rem;
    --nav-height-desktop-sticky: 5.2rem;
    --nav-height-desktop: 8.8rem;
    --lozenge-height: 4.8rem;
    --nav-height-mobile: 11.2rem;
    --spacing-xxs: .4rem;
    --spacing-xs: .8rem;
    --spacing-s: 1.2rem;
    --spacing-m: 1.6rem;
    --spacing-l: 2.4rem;
    --spacing-xl: 3.2rem;
    --spacing-xxl: 6.4rem;
    --spacing-xxxl: 12.8rem;
    --cardShadow: 0 10px 14px 0 rgb(0 0 0 / 3%);;
    --popupGradient: linear-gradient(180deg, rgb(22 26 39 / 0%) 15.57%, rgb(22 26 39 / 88%) 63.85%, var(--imageBackground) 100%);
    --controlsGradientDark: linear-gradient(270deg, rgb(32 40 67 / 70%) 0%, rgb(32 40 67 / 70%) 100%);
    --controlsGradientLight: linear-gradient(270deg, rgb(248 245 244 / 70%) 0%, rgb(248 245 244 / 70%) 100%);
    --bannerGradient: linear-gradient(257.32deg, rgb(22 26 39 / 0%) 9.19%, rgb(22 26 39 / 90%) 100.02%);
    --heroChannelGradientLight: linear-gradient(180deg, rgb(248 245 244 / 0.01%) 39.92%, rgb(248 245 244 / 80%) 72.52%, #F8F5F4 93.92%);
    --heroChannelGradientDark: linear-gradient(to bottom, rgb(32 40 67 / 0%) 40%, var(--neutral800) 100%);
    --default: 1.6rem;
    --large: 2rem;
    --header1: 6.4rem;
    --header2: 4rem;
    --header3: 3.9rem;
    --header4: 3.2rem;
    --header5: 2.5rem;
    --header6: var(--default);
    --meta: 1.4rem;
    --key: 1.3rem;
    --link: 1.2rem;
    --small: 1.1rem;
    --tiny: 1rem;
    --spacing: 1.6rem;
    background: var(--primary);
    color: var(--text);
    max-width: 100%;
    margin: 0;
    padding: 0;
    font-family: CentraNo2, helvetica, sans-serif;
    font-size: var(--default);
    overflow-x: hidden;

    @media (min-width: $break-tablet) {
        height: auto;
    }
}

.body-content {
    padding-top: var(--nav-height-mobile);

    @media (min-width: $break-tablet) {
        padding-top: calc(var(--nav-height-desktop) + var(--lozenge-height));
    }
}

button {
    color: inherit;
    cursor: pointer;
    border: none;
    background: inherit;
    font-family: inherit;
}

h1,h2,h3,h4,h5,h6, p {
    color: var(--black);
    margin: 0 0 var(--spacing-m);
}

ul {
    margin: 0;
}

*, *::before, *::after {
    box-sizing: border-box;
}

svg {
    fill: var(--text);
}

/* Remove Autocomplete styles */
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    border: none;
    -webkit-text-fill-color: inherit;
    box-shadow: 0 0 0 1000px white inset;
}
