.card-tile {
    $this: &;

    &__link {
        display: block;
        position: relative;

        &::after {
            background: linear-gradient(180deg, rgb(0 0 0 / 0%) 0%, #000 100%);
            border-radius: .8rem;
            content: '';
            inset: 0;
            position: absolute;
        }

        @include hover {
            #{$this}__bg-image {
                transform: scale(1.1);
            }
        }
    }

    &__inner {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        overflow: hidden;
    }

    &__content {
        inset: auto 0 0;
        margin: 0;
        padding: 0 var(--spacing-m) var(--spacing-xl);
        position: absolute;
        text-align: center;
        z-index: $level-one;
    }

    &__image {
        border-radius: .8rem;
        overflow: hidden;
    }

    &__bg-image {
        position: relative;
        height: 50rem;
        transition: transform .4s ease;
    }

    &__title {
        color: var(--white);
        font-weight: var(--bold);
        margin-bottom: 3.6rem;
        position: relative;

        &::after {
            background-color: var(--tertiary);
            bottom: -1.6rem;
            content: '';
            height: .4rem;
            left: 50%;
            position: absolute;
            transform: translateX(-50%);
            width: 8.6rem;
        }
    }

    &__description {
        color: var(--white);
        font-weight: var(--medium);
        line-height: 1.5;
        margin: 0;
    }

    @media (max-width: $break-tablet) {
        &:first-child {
            margin-left: 1.6rem;
        }

        &:last-child {
            margin-right: 1.6rem;
        }

        &__bg-image {
            height: 36.9rem;
        }

        &__content {
            padding: 0 var(--spacing-s) var(--spacing-s);
        }

        &__title {
            font-size: var(--header5);
            margin-bottom: 2.8rem;

            &::after {
                bottom: -1.6rem;
            }
        }
    }

    @media (max-width: $break-phablet)  {
        scroll-snap-align: center;
    }
}
