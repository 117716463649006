.content {
    background-color: var(--white, #fff);
    max-width: 87rem;
    text-align: center;

    @media (min-width: $break-tablet) {
        padding: var(--spacing-xxl) 0;
    }

    &::after {
        background-color: var(--tertiary);
        content: '';
        display: block;
        height: .4rem;
        margin-top: 6.4rem;

        @media (max-width: $break-tablet) {
            margin-top: var(--spacing-xl);
        }
    }

    &__title {
        font-size: var(--header2);
        margin: 0 0 var(--spacing-xl);
        text-align: center;
        color: var(--neutral900);

        @media (max-width: $break-tablet) {
            font-size: var(--header5);
            margin: 0 0 var(--spacing-l);
        }
    }

    &__content {
        p {
            font-size: var(--default);
            line-height: 1.5;

            @media (max-width: $break-tablet) {
                margin: 0 0 var(--spacing-l);
            }
        }

        a {
            font-size: inherit;
            color: var(--secondary-light);
            font-weight: var(--bold);
        }
    }

    &__partnership {
        position: relative;
        font-weight: var(--bold);
        font-size: var(--link);
        margin: 0;
        text-align: center;
        text-transform: uppercase;
    }
}