/* stylelint-disable selector-max-specificity */
.video-js .vjs-overlay {
    display: none;
}

// TODO: verify if this default_default classes are needed, looks like they only work on dev
.bc-player-default_default .vjs-big-play-button {
    margin: 0;
    width: 5.6rem;
    height: 5.6rem;
    background-color: var(--button);
    transform: translate(-50%, -50%);
}

.bc-player-default_default:hover .vjs-big-play-button {
    background-color: var(--neutral50);
}

.video-js .vjs-big-play-button .vjs-icon-placeholder::before {
    font-size: 3.6rem;
    line-height: 6;
    color: var(--primaryDark);
}

.video-jjs .vjs-slider,
.video-js .vjs-load-progress {
    background-color: #202843;
}

.video-js .vjs-load-progress div {
    background-color: rgba(#575b6e, 0.4);
}

.video-js .vjs-play-progress.vjs-slider-bar {
    background-color: var(--sliderBar);
}

.bc-player-default_default .vjs-control-bar {
    background-color: #141d38;
}

.bc-player-default_default .vjs-playlist-endscreen-control {
    display: none;
}

.bc-player-default_default .vjs-time-control.vjs-time-divider {
    padding: 0;
    width: auto;
    min-width: auto;
    font-size: 1.3rem;
    line-height: 3;
}

.bc-player-default_default .vjs-time-control .vjs-current-time-display,
.bc-player-default_default .vjs-time-control .vjs-duration-display {
    font-size: 1.3rem;
}

.video-js .vjs-play-control,
.video-js .vjs-mute-control,
.video-js .vjs-playback-rate,
.video-js .vjs-fullscreen-control,
.video-js .vjs-playlist-endscreen-control {
    border-right: 1px solid rgb(255 255 255 / 10%);
}

.video-js .vjs-playback-rate {
    padding: 0 3rem;
}

.video-js.bc-player-default_default .vjs-menu-item.vjs-menu-item {
    background-color: #202843;
}

.video-js.bc-player-default_default .vjs-menu-item.vjs-menu-item.vjs-selected {
    background-color: var(--button);
    color: var(--vjsSelectedText);

    @include hover {
        background-color: var(--vjsSelectedText);
        color: var(--button);
    }
}

.video-js.bc-player-default_default .vjs-menu-item.vjs-menu-item:hover {
    background-color: #141d38;
}

.video-js .vjs-volume-level {
    background-color: var(--button);
}

.bc-player-default_default
    .vjs-menu-button-popup
    .vjs-menu
    .vjs-menu-item:active,
.bc-player-default_default
    .vjs-menu-button-popup
    .vjs-menu
    .vjs-menu-item:focus,
.bc-player-default_default
    .vjs-menu-button-popup
    .vjs-menu
    .vjs-menu-item:hover {
    text-shadow: none;
}

.video-js .vjs-playlist-endscreen {
    display: none;
}
