.error-404 {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100dvh;
    justify-content: center;
    margin-top: calc(-1 * var(--nav-height-desktop));

    &__title {
        color: var(--secondary);
        font-size: var(--header1);
        margin: 3.2rem 0;
    }

    &__link {
        color: var(--text);
        display: flex;
        font-size: var(--header3);
        align-items: center;

        svg {
            height: 6.4rem;
            width: 6.4rem;
        }
    }

    @media (max-width: $break-desktop) {
        margin-top: calc(-1 * var(--nav-height-mobile));

        &__title {
            font-size: var(--header3);
        }

        &__link {
            font-size: var(--header5);

            svg {
                height: 3.2rem;
                width: 3.2rem;
            }
        }
    }
}