// Styles for: Cookie notice
// Notes: Cookie notice style for all devices

.cookie-notice {
    $this: &;
    z-index: $level-two;
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 3rem 4.4rem;
    background-color: var(--white);
    box-shadow: 0 -.1rem .2rem 0 rgb(0 0 0 / 10%);
    transition: transform .5s .25s;

    &--hide {
        transform: translateY(100%);
    }

    &__content {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &--left {
            padding-right: 3rem;
        }
    }

    &__title {
        font-size: 3.8rem;
        color: var(--secondary);
        margin-bottom: .5rem;
    }

    &__text,
    &__link {
        color: var(--text);
        font-size: 1.7rem;
        line-height: 1.6;
    }

    &__link {
        font-weight: var(--bold);
    }

    &__accept-button {
        padding: 1.6rem 6rem;
    }
}

@media (max-width: $break-tablet) {
    .cookie-notice {
        padding: 2rem 3.4rem;

        &__content {
            display: block;

            &--left {
                display: block;
                width: 100%;
                padding: 0;
            }

            &--right {
                display: block;
            }
        }

        &__title {
            font-size: 2.4rem;
        }

        &__text,
        &__link {
            font-size: 1.4rem;
        }

        &__accept-button {
            margin: 1rem 0;
            padding: 1.2rem 1.6rem;
        }
    }
}

;