// stylelint-disable

.autocomplete-suggestions {
    text-align: left;
    cursor: default;
    border: .1rem solid var(--neutral300);
    border-top: 0;
    background: var(--white);
    box-shadow: -.1rem .1rem .3rem rgba(0, 0, 0, .1);
    margin-top: -.1rem;

    /* core styles should not be changed */
    position: absolute;
    display: none;
    z-index: 9999;
    max-height: 25.4rem;
    overflow: hidden;
    overflow-y: auto;
    box-sizing: border-box;
}

.autocomplete-suggestion {
    position: relative;
    padding: .3rem 1rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 1.4rem;
    color: var(--neutral900);
}

.autocomplete-suggestion b {
    font-weight: normal;
    color: var(--neutral900);
}

.autocomplete-suggestion.selected {
    background: var(--neutral50);
}
