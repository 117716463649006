.button {
    align-items: center;
    background-color: var(--button);
    border-radius: 3.2rem;

    /* so we can animate on hover */
    border: 0.1rem solid var(--secondary);
    color: var(--white);
    cursor: pointer;
    display: flex;
    font-size: var(--default);
    font-weight: var(--bold);
    justify-content: center;
    line-height: 1.5;
    min-width: 14.8rem;
    padding: 1.1rem 3.2rem;
    transition:
        background-color 0.3s,
        border-color 0.3s,
        color 0.3s;

    svg {
        fill: currentcolor;
        flex-shrink: 0;
    }

    @include hover {
        border-color: var(--button);
        background-color: var(--white);
        color: var(--button);
        text-decoration: none;

        svg {
            fill: currentcolor;
        }
    }

    &--transparent {
        background-color: transparent;
        border-color: var(--button);
        border-radius: 5rem;
        color: var(--button);

        @include hover {
            border-color: var(--button);
            background-color: transparent;
            color: var(--button);
            text-decoration: none;

            svg {
                fill: currentcolor;
            }
        }
    }
}
