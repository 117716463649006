/* stylelint-disable selector-max-specificity */
.main-navigation {
    $this: &;
    height: 6.4rem;
    padding: 0 var(--spacing-m);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--white);
    transition: height 0.25s ease;
    will-change: height;
    z-index: 100;
    width: 100%;

    @media (min-width: $break-tablet) {
        border: none;
        height: auto;
        justify-content: flex-start;
    }

    @media (min-width: $break-desktop) {
        padding: 0 var(--spacing-xxl);
    }

    &__menu-link {
        height: 4rem;
        margin-right: auto;
        position: relative;
        transition: width 0.25s ease;
        width: 14rem;
        z-index: 106;
    }

    &__close-bar {
        background-color: var(--white);
        display: flex;
        height: 6.4rem;
        justify-content: flex-end;
        left: 0;
        padding: 0 var(--spacing-m);
        position: absolute;
        right: 0;
        top: calc(-1 * var(--nav-height-mobile));
    }

    &__menu-trigger {
        all: unset;
        align-items: center;
        display: flex;
        font-weight: var(--medium);
        line-height: 1;
    }

    &__close-icon,
    &__open-icon {
        height: 2.4rem;
        margin-left: .4rem;
        width: 2.4rem;
    }

    &__pos {
        align-items: center;
        background-color: var(--expediaBlue);
        display: flex;
        inset: 100% 0 auto;
        font-size: var(--link);
        height: 4.8rem;
        padding: 0 var(--spacing-m);
        position: absolute;
        text-align: center;
        z-index: 11;

        svg {
            fill: var(--white);
            height: 2rem;
            margin: 0 0 .9rem .2rem;
            width: 2rem;
        }
            
        @media (min-width: $break-tablet) {
            padding: 0 var(--spacing-xxl);
        }
    }

    &__nav {
        background-color: var(--primary);
        display: flex;
        flex-direction: column;
        left: 0;
        position: fixed;
        top: var(--nav-height-mobile);
        width: 100%;
        height: calc(100dvh - var(--nav-height-mobile));
        flex: 1;
        text-decoration: none;
        transition: transform 0.25s ease;
        transform: translateX(100%);
        will-change: transform;
        z-index: 105;

        .is-showing & {
            transform: translateX(0);
        }

        @media (min-width: $break-tablet) {
            flex-direction: row;
            margin-left: 10.3rem;
            height: var(--nav-height-desktop);
            position: relative;
            top: auto;
            transform: none;
        }

        .linked-list {
            display: block;

            @media (min-width: $break-tablet) {
            display: flex;
            }

            &__link,
            &__dropdown-label {
                align-items: center;
                color: var(--text);
                cursor: pointer;
                display: flex;
                font-size: var(--default);
                font-weight: var(--bold);
                justify-content: center;
                line-height: 1.5;
                padding: 1.6rem 0;
                text-align: center;
                width: 100%;

                @include hover {
                    text-decoration: none;
                }

                @media (min-width: $break-tablet) {
                    margin: 0;
                    padding: 3.2rem 0;
                }
            }
            
            > .linked-list__item {
                cursor: pointer;
                display: flex;
                width: 100%;
                justify-content: space-between;
                position: relative;

                &.has-children {
                    flex-direction: column;
                }

                &.is-active {
                    .linked-list__dropdown-label {
                        background-color: var(--secondary);
                        color: var(--white);

                        svg {
                            fill: var(--white);
                        }
                    }
                }
                
                &:last-child {
                    margin-right: 0;
                }

                &::after {
                    background-color: var(--secondary);
                    content: '';
                    height: .4rem;
                    inset: auto 0 0;
                    opacity: 0;
                    position: absolute;
                }

                @include hover {
                    &::after {
                        opacity: 1;
                    }

                    .linked-list {
                        &__dropdown {
                            opacity: 1;
                            transform: translateX(-50%) scale(1);
                        }

                        &__dropdown-icon {
                            transform: rotate(180deg);
                        }
                    }
                }

                @media (min-width: $break-tablet) {
                    min-width: 16.6rem;
                    position: relative;
                    transition: padding 0.25s ease;
                    z-index: 100;

                    &.has-children {
                        flex-direction: row;
                    }
                }
            }

            &__dropdown {
                all: unset;
                left: 50%;
                overflow: hidden;
                top: 100%;
                opacity: 0;
                position: relative;
                transform-origin: 5rem 0;
                transform: translateX(-50%);
                max-height: 0;
                transition: max-height .25s, opacity .25s ease;
                width: 27.5rem;

                .linked-list__link {
                    display: block;
                    font-weight: var(--light);
                    font-size: inherit;
                    line-height: 1.5;
                    padding: 2.4rem 2.5rem;
                    transition: background-color 0.25s ease;

                    @include hover {
                        color: var(--white);
                        background-color: var(--secondary);
                        text-decoration: none;
                    }
                }

                @media (min-width: $break-tablet) {
                    background: var(--white);
                    transform: scale(0);
                    transition: transform .25s ease, opacity .25s ease;
                    max-height: initial;
                    padding: .8rem;
                    position: absolute;

                    .linked-list__link {
                        font-weight: var(--regular);
                    }
                }
            }

            @media (max-width: $break-tablet) {
                .is-active {
                    .linked-list__dropdown {
                        opacity: 1;
                        max-height: initial;
                    }
        
                    .linked-list__dropdown-icon {
                        transform: rotate(180deg);
                    }
                }
            }

            &__dropdown-icon {
                height: 2.4rem;
                position: absolute;
                right: 1.2rem;
                transition: transform .25s ease;
                width: 2.4rem;

                @media (min-width: $break-tablet) {
                    align-self: center;
                    margin: 0 0 0 .6rem;
                    position: unset;
                }
            }
        }
    }

    &__right-content {
        display: flex;
        align-items: center;
        margin-left: auto;
    }

    &__pos-container {
        align-items: flex-end;
        display: flex;
        position: relative;

        &:hover {

            #{$this}__pos-info {
                transform: scale(1);
            }
        }
    }

    &__pos-link {
        height: 4.5rem;
        width: 8.5rem;
    }

    &__logo {
        height: 100%;
        width: 100%;
    }

    &__pos-info {
        color: var(--white);
        position: absolute;
        left: 0;
        top: var(--lozenge-height);
        width: 32rem;
        padding: 1.6rem;
        background-color: var(--expediaBlue);
        border-radius: 1.2rem;
        box-shadow: 0 8px 16px 0 rgb(157 158 161 / 24%);
        white-space: normal;
        font-weight: var(--light);
        text-align: center;
        transform: scale(0);
        transform-origin: 0 0;
        transition: .3s transform ease;
    }

    &__cta {
        order: 1;
        margin: auto var(--spacing-s) var(--spacing-s);

        @media (min-width: $break-tablet) {
            display: inline-block;
            margin: 0;
        }
    }
}