.cards-grid {
    overflow-x: hidden;
    position: relative;

    &__inner {
        position: relative;
    }

    &__header {
        margin-bottom: var(--spacing-xl);
        text-align: center;
    }

    &__title {
        margin: 0 auto var(--spacing-m);
        max-width: 87rem;
        text-align: center;
    }

    &__description {
        max-width: 87rem;
        margin: 0 auto;
        line-height: 1.8;
        text-align: center;
    }

    &__row {
        display: flex;
        flex-wrap: wrap;
        gap: 1.6rem;
    }

    &__item {
        flex-shrink: 0;
        max-width: calc(100% / 3 - 1.1rem);
        min-width: 0;
        width: 100%;
    }

    @media (max-width: $break-desktop) {
        &__item {
            max-width: calc(100% / 2 - .8rem);
        }
    }

    @media (max-width: $break-tablet) {
        &__inner {
            margin-bottom: 0;
        }

        &__header {
            margin-bottom: var(--spacing-l);
            padding: 0 var(--spacing-m);
        }

        &__row {
            flex-wrap: nowrap;
            justify-content: flex-start;
            margin: 0 -1.6rem;
            margin-top: 0;
            padding: 0 0 2rem;
            overflow-x: auto;
            scroll-snap-type: x mandatory;
            gap: 1.6rem;

            &::-webkit-scrollbar {
                height: .3rem;
            }

            &::-webkit-scrollbar-thumb {
                color: var(--secondary);
                background: var(--secondary);
            }
        }

        &__title {
            font-size: var(--header3);
        }

        &__item {
            max-width: 28rem;
            width: 100%;
        }
    }
}
