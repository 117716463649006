@use "sass:math";
/* stylelint-disable-next-line comment-empty-line-before */
/* SASS MODULES
========================================================================== */

@use 'sass:color';

/* SETTINGS
========================================================================== */

@import 'includes/settings/variables';

@import 'includes/global/mixins';

@import 'includes/global/mood-themes';

/* VENDORS
========================================================================== */

@import 'includes/vendors/auto-complete';
@import 'includes/vendors/datepicker';

/* LAYOUT
========================================================================== */

/* GLOBAL
========================================================================== */

@import 'includes/global/fonts';

@import 'includes/global/global';

@import 'includes/global/typography';

@import 'includes/global/transitions';

@import 'includes/global/animations';

/* COMPONENTS
========================================================================== */

@import "includes/components/_brightcove-player.scss";
@import "includes/components/_button.scss";
@import "includes/components/_datepicker.scss";
@import "includes/components/_forms.scss";
@import "includes/components/_linked-list.scss";
@import "includes/components/_meta-data.scss";
@import "includes/components/_object-fit-cover-picture.scss";
@import "includes/components/_static-hero.scss";
@import "includes/components/_tag.scss";
@import "includes/components/_video-play-cta.scss";
@import "includes/components/_widget-header.scss";
@import "includes/components/_widget.scss";
@import "includes/components/_wrapper.scss";

/* WIDGETS
========================================================================== */

@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/expedia-tampa-bay/404/styles/_404.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/expedia-tampa-bay/article-related/styles/_article-related.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/expedia-tampa-bay/banner-simplified/styles/_banner-simplified.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/expedia-tampa-bay/blog/styles/_blog.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/expedia-tampa-bay/booking/styles/_booking.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/expedia-tampa-bay/content-grid/styles/_card-tile.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/expedia-tampa-bay/content-grid/styles/_cards-grid.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/expedia-tampa-bay/content/styles/_content.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/expedia-tampa-bay/cookie-notice/styles/_cookie-notice.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/expedia-tampa-bay/embeddable-photo/styles/_embeddable-photo.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/expedia-tampa-bay/embeddable-quote/styles/_embeddable-quote.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/expedia-tampa-bay/footer/styles/_footer.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/expedia-tampa-bay/inline-video/styles/_inline-video.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/expedia-tampa-bay/media-promo/styles/_media-promo.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/expedia-tampa-bay/navigation/styles/_main-navigation.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/expedia-tampa-bay/quote/styles/_quote.scss";

/* UTILITIES
========================================================================== */

@import "includes/utilities/_trumps.scss";

/* VENDORS
========================================================================== */
// for Booking Widget
@import '../../node_modules/react-datepicker/dist/react-datepicker';
