.qs-datepicker-container {
    font-size: 1rem;
    font-family: sans-serif;
    color: #000;
    position: absolute;
    width: 15.625em;
    display: flex;
    flex-direction: column;
    z-index: 9001;
    user-select: none;
    border: 1px solid grey;
    border-radius: .26392em;
    overflow: hidden;
    background: #fff;
    box-shadow: 0 1.25em 1.25em -.9375em rgba(0, 0, 0, .3);
}

.qs-datepicker-container * {
    box-sizing: border-box;
}

.qs-datepicker-container.qs-centered {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.qs-datepicker-container.qs-hidden {
    display: none;
}

.qs-datepicker .qs-overlay {
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, .75);
    color: #fff;
    width: 100%;
    height: 100%;
    padding: .5em;
    z-index: 1;
    opacity: 1;
    transition: opacity .3s;
    display: flex;
    flex-direction: column;
}

.qs-datepicker .qs-overlay.qs-hidden {
    opacity: 0;
    z-index: -1;
}

.qs-datepicker .qs-overlay .qs-overlay-year {
    background: rgba(0, 0, 0, 0);
    border: none;
    border-bottom: 1px solid #fff;
    border-radius: 0;
    color: #fff;
    font-size: .875em;
    padding: .25em 0;
    width: 80%;
    text-align: center;
    margin: 0 auto;
    display: block;
}

.qs-datepicker .qs-overlay .qs-overlay-year::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

.qs-datepicker .qs-overlay .qs-close {
    padding: .5em;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
}

.qs-datepicker .qs-overlay .qs-submit {
    border: 1px solid #fff;
    border-radius: .26392em;
    padding: .5em;
    margin: 0 auto auto;
    cursor: pointer;
    background: hsla(0, 0%, 50.2%, .4);
}

.qs-datepicker .qs-overlay .qs-submit.qs-disabled {
    color: grey;
    border-color: grey;
    cursor: not-allowed;
}

.qs-datepicker .qs-overlay .qs-overlay-month-container {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
}

.qs-datepicker .qs-overlay .qs-overlay-month {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 33.33333%;
    cursor: pointer;
    opacity: .5;
    transition: opacity .15s;
}

.qs-datepicker .qs-overlay .qs-overlay-month.active,
.qs-datepicker .qs-overlay .qs-overlay-month:hover {
    opacity: 1;
}

.qs-datepicker .qs-controls {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-grow: 1;
    flex-shrink: 0;
    background: #d3d3d3;
    filter: blur(0);
    transition: filter .3s;
}

.qs-datepicker .qs-controls.qs-blur {
    filter: blur(5px);
}

.qs-datepicker .qs-arrow {
    height: 1.5625em;
    width: 1.5625em;
    position: relative;
    cursor: pointer;
    border-radius: .26392em;
    transition: background .15s;
}

.qs-datepicker .qs-arrow:hover {
    background: rgba(0, 0, 0, .1);
}

.qs-datepicker .qs-arrow:hover.qs-left::after {
    border-right-color: #000;
}

.qs-datepicker .qs-arrow:hover.qs-right::after {
    border-left-color: #000;
}

.qs-datepicker .qs-arrow::after {
    content: '';
    border: .39062em solid rgba(0, 0, 0, 0);
    position: absolute;
    top: 50%;
    transition: border .2s;
}

.qs-datepicker .qs-arrow.qs-left::after {
    border-right-color: grey;
    right: 50%;
    transform: translate(25%, -50%);
}

.qs-datepicker .qs-arrow.qs-right::after {
    border-left-color: grey;
    left: 50%;
    transform: translate(-25%, -50%);
}

.qs-datepicker .qs-month-year {
    font-weight: 700;
    transition: border .2s;
    border-bottom: 1px solid rgba(0, 0, 0, 0);
    cursor: pointer;
}

.qs-datepicker .qs-month-year:hover {
    border-bottom: 1px solid grey;
}

.qs-datepicker .qs-month-year:active:focus,
.qs-datepicker .qs-month-year:focus {
    outline: none;
}

.qs-datepicker .qs-month {
    padding-right: .5ex;
}

.qs-datepicker .qs-year {
    padding-left: .5ex;
}

.qs-datepicker .qs-squares {
    display: flex;
    flex-wrap: wrap;
    padding: .3125em;
    filter: blur(0);
    transition: filter .3s;
}

.qs-datepicker .qs-squares.qs-blur {
    filter: blur(5px);
}

.qs-datepicker .qs-square {
    width: 14.28571%;
    height: 1.5625em;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background .1s;
    border-radius: .26392em;
}

.qs-datepicker .qs-square.qs-current {
    font-weight: 700;
}

.qs-datepicker .qs-square.qs-current span {
    text-decoration: underline;
}

.qs-datepicker .qs-square.qs-active {
    background: #add8e6;
}

.qs-datepicker .qs-square.qs-range-date-start:not( .qs-range-date-6 ) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.qs-datepicker .qs-square.qs-range-date-middle {
    background: #d4ebf2;
}

.qs-datepicker .qs-square.qs-range-date-middle:not( .qs-range-date-0 ):not( .qs-range-date-6 ) {
    border-radius: 0;
}

.qs-datepicker .qs-square.qs-range-date-middle.qs-range-date-0 {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.qs-datepicker .qs-square.qs-range-date-end:not( .qs-range-date-0 ),
.qs-datepicker .qs-square.qs-range-date-middle.qs-range-date-6 {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.qs-datepicker .qs-square.qs-disabled span {
    opacity: .2;
}

.qs-datepicker .qs-square.qs-empty {
    cursor: default;
}

.qs-datepicker .qs-square.qs-disabled {
    cursor: not-allowed;
    color: var(--neutral300);
}

.qs-datepicker .qs-square.qs-day {
    cursor: default;
    font-weight: 700;
    color: grey;
}

.qs-datepicker .qs-square:not( .qs-empty ):not( .qs-disabled ):not( .qs-day ):not( .qs-active ):hover {
    background: orange;
}

.qs-datepicker .qs-event {
    position: relative;
}

.qs-datepicker .qs-event::after {
    content: '';
    position: absolute;
    width: .46875em;
    height: .46875em;
    border-radius: 50%;
    background: #07f;
    bottom: 0;
    right: 0;
}

.qs-datepicker .qs-event.qs-disabled::after,
.qs-datepicker .qs-event.qs-empty::after {
    background: #cce4ff;
}

// stylelint-enable
