// Styles for: form
// Notes: form style for all devices

// stylelint-disable

// styling for standard fieldset

fieldset {
    margin: 0;
    padding: 0;
    border: none;
}

.fieldset {
    padding: 0 4rem 4rem 4rem;
    border-radius: 0;
    margin: 0 0 4rem 0;
    border: .1rem solid var(--neutral200);
    position: relative;
}

// legend

legend {
    font-size: 1.8rem;
    margin: 0 0 1.2rem 0;
    line-height: 1;
    display: block;
    font-weight: normal;
}

.legend {
    font-size: 2.8rem;
    margin: 0 0 3rem 0;
    line-height: 1;
    display: block;
    font-family: var(--font-family);
    font-weight: normal;
}

// label

label,
.label {
    font-size: var(--meta);
    font-weight: var(--bold);
    color: var(--neutral900);
    margin-bottom: 1.2rem;
    display: block;
    text-transform: none;
}

.label-helper {
    font-size: 1.5rem;
    margin: -1rem 0 1.2rem 0;
    color: var(--neutral800);
    line-height: 1.4;
}

// inputs/textareas/radio/checkboxes

input[type='text'],
input[type='password'],
input[type='date'],
input[type='datetime'],
input[type='datetime-local'],
input[type='month'],
input[type='week'],
input[type='email'],
input[type='number'],
input[type='search'],
input[type='tel'],
input[type='time'],
input[type='url'],
input[type='color'],
input[type='search'],
textarea,
select {
    padding: $spacing-s;
    appearance: none;
    border-radius: .4rem;
    background-color: var(--white);
    border: solid .1rem var(--neutral200);
    display: block;
    font-family: inherit;
    font-size: 1.6rem;
    width: 100%;
    height: 4.8rem;
    color: var(--neutral500);
    box-sizing: border-box;
    max-width: 80rem;

    &::-webkit-input-placeholder {
        color: var(--neutral500);
        font-size: inherit;
    }

    &:-moz-placeholder {
        color: var(--neutral500);
        font-size: inherit;
    }

    &::-moz-placeholder {
        color: var(--neutral500);
        font-size: inherit;
    }

    &::-ms-input-placeholder {
        color: var(--neutral500);
        font-size: inherit;
    }

    &:focus {
        border: .1rem solid var(--neutral800);
        outline: none;
    }

    &:disabled {
        background-color: var(--neutral100);
        cursor: not-allowed;
        color: var(--neutral400);
    }
}

textarea {
    padding: 1.7rem 3rem;
    min-height: 10rem;
    border-radius: 1.5rem;
}

select {
    padding: 0 5rem 0 2rem;
    line-height: 2.6;
    // hide dropdown arrow in internet explorer

    &::-ms-expand {
        display: none;
    }
}

select[multiple] {
    padding: 1.5rem 3rem;
    border-radius: 2rem;
    background: var(--neutral200);
    height: inherit;
}

input[type='checkbox'],
input[type='radio'] {
    font-size: 1.6rem;

    + label {
        margin: 0 2rem 0 0;
        font-size: 1.6rem;
        font-weight: normal;
        color: var(--neutral500);
        display: flex;
        align-items: center;
    }
}

input[type='file'] {
    font-size: 1.6rem;
}

// custom checkboxes and custom radio button

input[type='radio'].custom-radio {
    padding: 0;
    border: 0;
    clip: rect(0 0 0 0);
    height: .1rem;
    margin: -.1rem;
    overflow: hidden;
    position: absolute;
    width: .1rem;
    top: 2.3rem;
    margin-left: 1.3rem;
}

input[type='radio'].custom-radio ~ label::before {
    width: 2.3rem;
    height: 2.3rem;
    margin-right: 1rem;
    content: '';
    display: inline-block;
    background: var(--neutral200);
    border: .4rem solid var(--white);
    border-radius: 50%;
    -webkit-border-radius: 50%;
    box-shadow: 0 0 0 .1rem var(--neutral200);
    vertical-align: middle;
    position: relative;
    top: -.1rem;
}

input[type='radio'].custom-radio:focus ~ label::before {
    box-shadow: 0 0 0 .1rem var(--secondary);
}

input[type='radio'].custom-radio:checked ~ label::before {
    background: var(--secondary);
    vertical-align: middle;
}

input[type='checkbox'].custom-checkbox,
input[type='radio'].custom-checkbox {
    padding: 0;
    border: 0;
    clip: rect(0 0 0 0);
    height: .1rem;
    margin: -.1rem;
    overflow: hidden;
    position: absolute;
    width: .1rem;
    top: 2.3rem;
    margin-left: 1.3rem;
}

input[type='checkbox'].custom-checkbox ~ label::before,
input[type='radio'].custom-checkbox ~ label::before {
    content: '';
    display: inline-block;
    background: transparent;
    border: .1rem solid var(--neutral200);
    border-radius: .2rem;
    vertical-align: middle;
    position: relative;
    width: 2.4rem;
    height: 2.4rem;
    margin-right: $spacing-s;
}

input[type='checkbox'].custom-checkbox:focus ~ label::before,
input[type='radio'].custom-checkbox:focus ~ label::before {
    border: .1rem solid var(--secondary);
}

input[type='checkbox'].custom-checkbox:disabled ~ label::before,
input[type='radio'].custom-checkbox:disabled ~ label::before,
input[type='checkbox'].custom-checkbox:disabled ~ label,
input[type='radio'].custom-checkbox:disabled ~ label {
    opacity: .5;
}

input[type='checkbox'].custom-checkbox:checked ~ label::before,
input[type='radio'].custom-checkbox:checked ~ label::before {
    content: '';
    vertical-align: middle;
    background-image: url('../i/elements/tick.svg');
    background-size: 2rem;
    background-position: center;
    background-repeat: no-repeat;
}

input[type='radio'].custom-radio ~ label,
input[type='checkbox'].custom-checkbox ~ label,
input[type='radio'].custom-checkbox ~ label {
    margin-bottom: 2rem;
}

// required

.required {
    color: var(--secondary);
}

// input container

.input-container {
    margin-right: 7rem;
    position: relative;

    &.is-error {

        input,
        textarea {
            border: .2rem solid o(var(--secondary), 50);
        }
    }

    &.is-valid {

        input,
        textarea {
            border: .2rem solid o(var(--secondary), 50);
        }
    }
}

// input group

.input-group {
    display: table;
    width: 100%;

    input {
        white-space: nowrap;
        display: table-cell;
        vertical-align: middle;
    }

    .input-group-button {
        padding-top: 0;
        padding-bottom: 0;
        margin: 0;
        display: table-cell;
        text-align: center;
        vertical-align: top;
        width: 1%;

        button {
            margin-left: 2rem;
            white-space: nowrap;
        }
    }
}
