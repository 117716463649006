.blog {
    &__header {
        position: relative;
        height: calc(100dvh - calc(var(--nav-height-desktop) + var(--lozenge-height)));
        max-height: 63.9rem;
    }

    &__image {
        all: unset;
        inset: 0;
        position: absolute;
        
        &::before {
            background: linear-gradient(0deg, rgb(0 0 0 / 37%) 53.11%, rgb(0 0 0 / 27%) 62.77%, rgb(0 0 0 / 0%) 92.52%) no-repeat;
            content: '';
            inset: 0;
            pointer-events: none;
            position: absolute;
            z-index: $level-one;
        }
        
        &::after {
            background: linear-gradient(180deg, rgb(0 0 0 / 0%) 0%, rgb(0 0 0 / 40%) 100%);
            content: '';
            height: 50.3rem;
            inset: auto 0 0;
            pointer-events: none;
            position: absolute;
            z-index: $level-one;
        }
    }

    &__intro {
        inset: auto 0 0;
        position: absolute;
        margin-bottom: 0;
        padding-bottom: 0;
        padding-top: 0;
        z-index: $level-two;
    }

    &__heading {
        color: var(--tertiary);
        font-size: var(--header2);
        margin-bottom: var(--spacing-xl);
        max-width: 53.5rem;

        &--small {
            font-size: var(--header2);
        }
    }

    &__summary {
        color: var(--white);
        font-weight: var(--bold);
        margin-bottom: var(--spacing-xl);
        max-width: 53.5rem;
    }

    &__meta {
        align-items: flex-end;
        border-top: .1rem solid var(--white);
        color: var(--white);
        display: flex;
        font-size: 1.6rem;
        padding: var(--spacing-xl) 0 var(--spacing-l);
        justify-content: space-between;
        line-height: 1;
    }

    &__date {
        font-size: var(--meta);

        &::after {
            content: '•';
            display: inline;
            font-size: 1.5rem;
            margin-right: .5rem;
            margin-left: .8rem;
        }
    }

    &__read-time {
        font-size: var(--meta);
    }

    &__author,
    &__caption {
        &::before {
            content: '•';
            font-size: var(--meta);
            display: inline;
            margin-right: .8rem;
            margin-left: .5rem;
        }
    }

    &__caption {
        font-size: var(--meta);
        font-weight: var(--regular);
        float: right;

        &::before {
            display: none;
        }
    }

    &__content {
        position: relative;
        max-width: 64.6rem;
        margin: 0 auto;

        h1,h2,h3,h4,h5,h6 {
            color: var(--secondary-light);
        }
    }

    &__share {
        position: absolute;
        top: 0;
        right: -27rem;
        width: 8rem;
        min-height: 30rem;
    }

    &__cta {
        align-items: center;
        display: flex;
        position: relative;
        max-width: 64.6rem;
        margin: 0 auto;
        padding: 4.4rem 0;
        text-align: center;

        &::before {
            background-color: var(--neutral300);
            position: absolute;
            width: 100%;
            height: .1rem;
            content: '';
        }
    }

    &__cta-button {
        background-color: var(--white);
        display: block;
        margin: 0 auto;
        min-width: 12.8rem;
        padding: 1rem 1.6rem;
        text-align: center;
    }

    iframe {
        aspect-ratio: 16/9;
        width: 100%;
    }

    @media (max-width: $break-tablet) {
        &__header {
            height: calc(100dvh - var(--nav-height-mobile));
            max-height: 60rem;
        }

        &__heading {
            font-size: var(--header4);
            margin-bottom: var(--spacing-m);

            &--small {
                font-size: var(--header4);
            }
        }

        &__summary {
            font-size: var(--default);
            margin-bottom: var(--spacing-m);
        }

        &__meta {
            padding: var(--spacing) 0 var(--spacing-l);
        }

        &__cta {
            &::before {
                content: normal;
            }
        }

        &__cta-button {
            width: 100%;
            text-align: center;
        }
    }
}
